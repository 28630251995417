import "../css/formcompo.css";
import form from "../cssMod/formcompo.module.css";
import NewFilterCompo from "./NewFilterCompo";
import React from "react";
import { Input } from "reactstrap";
import JoditEditor from "jodit-react";
import MultipleSelectCompo from "./multipleSelectCompo";
import { UncontrolledTooltip } from "reactstrap";
import { AppConfig } from "../Utility/AppConfig";
import { Editor } from "@monaco-editor/react";

class FormInnerComponent extends React.Component {
  state = {
    obj: this.props.obj,
    m_rid: this.props.m_rid,
    index: this.props.index,
    record: this.props.record,
    tabname: this.props.tabname,
    setm_rid: this.props.setm_rid,
    validationfn: this.props.validationfn,
    formChangefn: this.props.formChangefn,
    refrecord: this.props.refrecord,
    setcolumn: this.props.setcolumn,
    calltimeline: this.props.calltimeline,
    callfilter: this.props.callfilter,
    callScript: this.props.callScript,
    setRefrecord: this.props.setRefrecord,
    choice_mn: this.props.choice_mn,
    showlist: this.props.showlist,
    col_mn: this.props.col_mn,
    column_depend: this.props.col_depend,
    filtarray: this.props.filtarray,
    timeline: this.props.timeline,
    loca: this.props.loca,
    tabId: this.props.tabId,
    isMobile: this.props.isMobile,
    column_other: this.props.column_other,
    reScript: this.props.reScript,
    editor: this.props.editor,
    mscList: this.props.mscList,
    setMSC: this.props.setMSC,
    setContextMenu: this.props.setContextMenu,
    ob: this.props.ob,
    validation_error: this.props.validation_error,
    validation: this.props.validation,
    verify_error: this.props.verify_error,
    setref_filter: this.props.setref_filter,
    col_mn_ref: this.props.col_mn_ref,
    keyValueJson: this.props.keyValueJson,
    groupkeyValue: this.props.groupkeyValue,
    getSingleInfo: this.props.getSingleInfo,
    showRecent: this.props.showRecent,
    imageloca: AppConfig.imageloca,
    editorHeight: "15vh",
    multiUpdate: this.props.multiUpdate,
    rowIndex: this.props.rowIndex,
  };

  constructor(props) {
    super(props);
    this.handleAddParentInput = this.handleAddParentInput.bind(this);
    this.handleAddChildInput = this.handleAddChildInput.bind(this);
    this.handleRemoveParentInput = this.handleRemoveParentInput.bind(this);
    this.handleRemoveChildInput = this.handleRemoveChildInput.bind(this);
    console.log("obj", this.state.obj);
    
  }

  componentDidMount() {
    let obj = this.state.obj;
    if (obj.type === "group_key_value") {
      this.setState({ groupkeyValue: obj.value });
    }
    if (obj.type === "image_upload") {
      obj.localimg = false;
    }

    return obj;
  }

  componentDidUpdate(props) {
    if (this.props.obj != this.state.obj) {
      this.setState({ obj: this.props.obj });
    }
    if (
      props.col_mn !== this.state.col_mn ||
      props.col_depend !== this.state.column_depend ||
      props.col_mn_ref !== this.state.col_mn_ref ||
      props.refrecord !== this.state.refrecord ||
      props.choice_mn !== this.state.choice_mn ||
      props.verify_error !== this.state.verify_error
    ) {
      this.setState({
        col_mn: props.col_mn,
        refrecord: props.refrecord,
        col_mn_ref: props.col_mn_ref,
        column_depend: props.col_depend,
        choice_mn: props.choice_mn,
        verify_error: props.verify_error,
      });
    }
  }

  // getCss=(obj)=>{

  //    if(obj.verified === "unverified"){
  //     return   this.state.multiUpdate?"form-control form-control-multiUpdate formpadd_danger unverifi":"form-control formpadd_danger unverifi";

  //    }else{
  //  return   this.state.multiUpdate?"form-control  form-control-multiUpdate formpadd":"form-control formpadd";
  //    }
  // }

  // getCssForChoice=(obj)=>{
  //   if(obj.verified === "unverified"){
  //     return   this.state.multiUpdate?"form-control form-control-multiUpdate  form-select formpadd_danger unverifi":"form-control form-select formpadd_danger unverifi";
  //    }else{
  //  return   this.state.multiUpdate?"form-control  form-control-multiUpdate form-select formpadd":"form-control  form-select formpadd";
  //    }
  // }

 getUivalidCode=()=>{

  return ( <>
  {this.state.obj.uivalid.mandatory === "true" &&
    this.state.obj.value.value !== "" && (
      <i
        className="fa fa-asterisk mndtryfalse"
        style={{marginTop: "5%"}}
        aria-hidden="true"
      ></i>
    )}
  {this.state.obj.uivalid.mandatory === "true" &&
    this.state.obj.uivalid.group_mandatory === "false" &&
    this.state.obj.value.value === "" && (
      <i
        className="fa fa-asterisk mndtrytrue"
        style={{marginTop: "5%"}}
        aria-hidden="true"
      ></i>
    )}

  {this.state.obj.uivalid.mandatory === "true" &&
    this.state.obj.uivalid.group_mandatory === "true" &&
    this.state.obj.value.value === "" && (
      <i
        className="fa fa-asterisk mndtrytrue"
        style={{marginTop: "5%"}}
        aria-hidden="true"
      ></i>
    )}
  </>)
  }

  getCss = (obj) => {
    if (obj.type === "reference") {
      // this.state.obj.verified === "unverified"
      // ? "form-control formpadd formbor unverifi"
      // : "form-control formpadd formbor"
      if (obj.verified === "unverified") {
        return this.state.multiUpdate
          ? "form-control formpadd form-control-multiUpdate formbor unverifi"
          : "form-control formpadd formbor unverifi";
      } else {
        return this.state.multiUpdate
          ? "form-control form-control-multiUpdate  formpadd formbor"
          : "form-control formpadd formbor";
      }
    } else if (obj.type === "choice" || obj.type === "boolean") {
      if (obj.verified === "unverified") {
        return this.state.multiUpdate
          ? "form-control form-control-multiUpdate  form-select formpadd_danger unverifi"
          : "form-control form-select formpadd_danger unverifi";
      } else {
        return this.state.multiUpdate
          ? "form-control  form-control-multiUpdate form-select formpadd"
          : "form-control  form-select formpadd";
      }
    } else if(obj.type === "date" ){
      // this.state.obj.verified === "unverified"
      // ? "objpadd_tm unverifi"
      // : "objpadd_tm"

      if (obj.verified === "unverified") {
        return this.state.multiUpdate
          ? "objpadd_tm form-control-multiUpdate unverifi "
          : "objpadd_tm unverifi";
      } else {
        return this.state.multiUpdate
          ? "objpadd_tm form-control-multiUpdate "
          : "objpadd_tm";
      }

    }
    else if(obj.type==="time"){
      console.log("timeeee");
      
      // this.state.obj.verified === "unverified"
      // ? "objpadd_tm unverifi"
      // : "objpadd_tm"

      if (obj.verified === "unverified") {
        return this.state.multiUpdate
          ? "objpadd_tm form-control-multiUpdate unverifi "
          : "objpadd_tm unverifi";
      } else {
        return this.state.multiUpdate
          ? "objpadd_tm form-control-multiUpdate "
          : "objpadd_tm";
      }

    }
     else {
      // this.state.obj.verified === "unverified"
      // ? "form-control formpadd_danger unverifi"
      // : "form-control formpadd "
      if (obj.verified === "unverified") {
        return this.state.multiUpdate
          ? "form-control form-control-multiUpdate formpadd_danger unverifi"
          : "form-control formpadd_danger unverifi";
      } else {
        return this.state.multiUpdate
          ? "form-control  form-control-multiUpdate formpadd"
          : "form-control formpadd";
      }
    }
  };

  getCssOnBooleanChoice = (obj) => {
    if (this.state.multiUpdate) return "";
    else return "";
  };

  addInput = (obj) => {
    let rcd = this.state.record;
    let t = {};
    let properties = JSON.parse(JSON.stringify(obj.value));
    properties.push(t);
    this.state.obj.value.push(t);
    //setRecord([...record]);
    this.setState({ record: this.state.record });
  };

  removeInput = (index, obj) => {
    let array = JSON.parse(JSON.stringify(obj.value));
    if (index !== -1) {
      array.splice(index, 1);
    }
    obj.value = array;
    this.setState({ record: this.state.record });
  };

  handleExpandClick = () => {
    // Toggle between expanded and normal height
    const newHeight = this.state.editorHeight === "15vh" ? "100vh" : "15vh";
    // setEditorHeight(newHeight);
    this.setState({ editorHeight: newHeight });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.col_mn !== state.col_mn) {
      return {
        col_mn: props.col_mn,
      };
    }
    if (props.verify_error !== state.verify_error) {
      return {
        verify_error: props.verify_error,
      };
    }
    if (
      props.validation !== state.validation ||
      props.validation_error !== state.validation_error ||
      props.ob !== state.ob
    ) {
      return {
        validation: props.validation,
        validation_error: props.validation_error,
        ob: props.ob,
      };
    }
    return null;
  }

  handleAddParentInput() {
    let t = {
      name: "",
      choice: [{ value: "" }],
    };
    let properties = this.state.groupkeyValue.properties;
    properties.push(t);
    this.setState({ groupkeyValue: properties });
  }

  handleAddChildInput(parentIndex) {
    let t = { value: "" };
    let childProps = this.state.groupkeyValue.properties[parentIndex].choice;
    childProps.push(t);
    this.state.groupkeyValue.properties[parentIndex].choice = childProps;
    this.setState({
      groupkeyValue: { properties: this.state.groupkeyValue.properties },
    });
  }

  handleRemoveParentInput(parentIndex) {
    let array = this.state.groupkeyValue.properties;
    if (parentIndex !== -1) {
      array.splice(parentIndex, 1);
    }
    let prp = {};
    prp.properties = array;
    this.setState({ groupkeyValue: prp });
  }

  handleRemoveChildInput(parentIndex, childindex) {
    let array = this.state.groupkeyValue.properties[parentIndex].choice;
    if (childindex !== -1) {
      array.splice(childindex, 1);
    }
    this.state.groupkeyValue.properties[parentIndex].choice = array;
    this.setState({
      groupkeyValue: { properties: this.state.groupkeyValue.properties },
    });
  }

  render() {
    return (
      <div>
        {this.state.obj.columnAccess === "true" &&
          this.state.obj.uivalid.visible === "true" && (
            <div className="  inppd " key={this.state.obj.name}>
              {this.state.obj.type === "note" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}

                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;

                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your character!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate  && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <textarea
                    type="text"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd areaheight"
                    }
                    spellCheck={false}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  ></textarea>
                </div>
              ) : null}
              {this.state.obj.type === "script" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}

                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;

                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                  >
                  {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your character!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate  && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}

                  <div>
                    <div>
                      <i
                        className="fa-solid fa-expand"
                        onClick={this.handleExpandClick}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>

                    {this.state.editorHeight === "15vh" ? (
                      <div
                        style={{ border: "2px solid black", height: "15vh" }}
                      >
                        <Editor
                          // width="99vw"
                          // height='100vh'
                          language="javascript"
                          theme="vs"
                          value={this.state.obj.value}
                          readOnly={this.state.obj.uivalid.read_only === "true"}
                          maxLength={this.state.obj.uivalid.max_length}
                          onChange={(e, i) => {
                            this.state.formChangefn(
                              e,
                              this.state.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              false,
                              this.state.obj.uivalid.read_only,
                              "",
                              "",
                              "",
                              this.state.obj
                            );
                          }}
                          /*                 onChange={(e, i) => {
                            formChangefn(
                              e,
                              obj.index,
                              obj.name,
                              obj.type,
                              obj.id,
                              false
                            );
                          }} */
                          options={{
                            codeLens: false,
                            inlineSuggest: true,
                            fontSize: "16px",
                            formatOnType: true,
                            minimap: {
                              enabled: false,
                              showSlider: "always",
                            },
                          }}
                          onBlur={(e) =>
                            this.state.validationfn(
                              e,
                              this.state.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              this.state.obj
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          border: "2px solid black",
                          background: "white",
                          height: "100vh",
                          width: "99.1vw",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          zIndex: 1050,
                        }}
                      >
                        <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                          <i
                            className="fa-solid fa-compress"
                            style={{ cursor: "pointer" }}
                            onClick={this.handleExpandClick}
                          ></i>
                        </div>
                        <Editor
                          language="javascript"
                          theme="vs"
                          value={
                            this.state.obj.value === undefined
                              ? ""
                              : this.state.obj.value
                          }
                          readOnly={this.state.obj.uivalid.read_only === "true"}
                          maxLength={this.state.obj.uivalid.max_length}
                          onChange={(e, i) => {
                            this.state.formChangefn(
                              e,
                              this.state.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              false,
                              this.state.obj.uivalid.read_only,
                              "",
                              "",
                              "",
                              this.state.obj
                            );
                          }}
                          options={{
                            codeLens: false,
                            inlineSuggest: true,
                            fontSize: "16px",
                            formatOnType: true,
                            minimap: {
                              enabled: false,
                              showSlider: "always",
                            },
                          }}
                          onBlur={(e) =>
                            this.state.validationfn(
                              e,
                              this.state.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              this.state.obj
                            )
                          }
                        />
                      </div>
                    )}
                    <div
                      id="suggetion"
                      style={{
                        position: "absolute",
                        /*       left: x + 22,
                        top: y + 25, */
                      }}
                      onFocus={() => {
                        let d = document.getElementById("suggetion");
                        d.style.background = "blue";
                        d.style.color = "white";
                      }}
                    ></div>
                  </div>

                  {/*       <textarea
                    type="text"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd areaheight"
                    }
                    spellCheck={false}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id
                      )
                    }
                  ></textarea> */}
                </div>
              ) : null}
         
              {this.state.obj.type === "String" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "false" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue" //mndtrygptrue
                        aria-hidden="true"
                      ></i>
                    )}

                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;

                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.label?.showHelper === "true" && (
                    <div className="alert alert-warning">
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontWeight: "bold",
                          margin: "0rem",
                          color: "black",
                        }}
                      >
                        {this.state.obj.label.helper}
                      </pre>
                    </div>
                  )}

                  {this.state.obj?.ob === this.state.obj.name &&
                    this.state.obj?.validation_error === true &&
                    this.state.obj?.fld_valid !== "" && (
                      <span className="popup_txt_form popuptext" id="myPopup">
                        {this.state.obj?.fld_valid}
                      </span>
                    )}
                  {this.state.obj?.verified === "min_unverified" && !this.state.multiUpdate  && (
                    <div className="alert alert-danger form_alt" role="alert">
                      {this.state.obj?.verify_error}
                    </div>
                  )}

                  {/* {this.state.ob === this.state.obj.name &&
                    this.state.obj.value.length !== 0 &&
                    this.state.validation_error === true &&
                    this.state.validation !== "" && this.state.obj.uivalid.min_length !== 0 && (this.state.validation === `Number Should Be At Least ${this.state.obj.uivalid.min_length}` || this.state.validation === `Character Should Be At Least ${this.state.obj.uivalid.min_length}` || this.state.validation === `Length Should Be At Least ${this.state.obj.uivalid.min_length} character long`) && (
                      <span className="popup_txt_form popuptext" id="myPopup1">
                        {this.state.validation}
                      </span>
                    )} */}

                  <Input
                    type={
                      this.state.obj.validation === "number" || this.state.obj.validation === "decimal" ? "tel" : "text"
                    }
                    id="inpt"
                    className={this.getCss(this.state.obj)}
                    // invalid={
                    //   (this.state.ob === this.state.obj.name && this.state.validation_error === true && this.state.validation !== "" && this.state.validation === "Only Accept Number") ||
                    //   (this.state.ob === this.state.obj.name && this.state.obj.value.length !== 0 && this.state.validation_error === true && this.state.validation !== "" && this.state.obj.uivalid.min_length !== 0 && (this.state.validation === `Number Should Be At Least ${this.state.obj.uivalid.min_length}` || this.state.validation === `Character Should Be At Least ${this.state.obj.uivalid.min_length}`))
                    // }
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </Input> */}
                </div>
              ) : null}
              {this.state.obj.type === "int" ? (
                <div
                  className="form-group"
                  /*  style={{ width: "100%"}} */
                >
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "false" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}

                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                    {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label?.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}

                  {!this.state.multiUpdate &&
                    this.state.obj.verified === "unverified" && (
                      <div className="alert alert-danger form_alt" role="alert">
                        Please verify your integer number!
                      </div>
                    )}
                  {!this.state.multiUpdate &&
                    this.state.obj.verified === "min_unverified" && !this.state.multiUpdate  && (
                      <div className="alert alert-danger form_alt" role="alert">
                        {this.state.obj?.verify_error}
                      </div>
                    )}
                  <input
                    type="tel"
                    className={this.getCss(this.state.obj)}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "aadhar_number" ? (
                <div className="form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                      {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your aadhar number!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="text"
                    datatype="aadhar_number"
                    placeholder="0000-0000-0000"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd "
                    }
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "dynamic_key_value" && (
                <div className="form-group ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    ((Object.keys(this.state.obj.value[0])[0] !== "" &&
                      Object.keys(this.state.obj.value[0]).length > 0) ||
                      (Object.values(this.state.obj.value[0])[0] !== "" &&
                        Object.values(this.state.obj.value[0]).length > 0)) && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    (Object.keys(this.state.obj.value[0])[0] === "" ||
                      Object.keys(this.state.obj.value[0]).length === 0) &&
                    (Object.values(this.state.obj.value[0])[0] === "" ||
                      Object.values(this.state.obj.value[0]).length === 0) &&
                    this.state.obj.uivalid.group_mandatory === "false" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "true" &&
                    (Object.keys(this.state.obj.value[0])[0] === "" ||
                      Object.keys(this.state.obj.value[0]).length === 0) &&
                    (Object.values(this.state.obj.value[0])[0] === "" ||
                      Object.values(this.state.obj.value[0]).length === 0) && (
                      <i
                        className="fa fa-asterisk mndtrygptrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;

                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>

                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.value.map((objj, outerIndex) => (
                    <div className="d-flex" key={outerIndex}>
                      <div className="d-flex">
                        <input
                          type="text"
                          name="child"
                          className="form-control formpadd me-2 my-1"
                          value={Object.keys(objj)[0]}
                          readOnly={this.state.obj.uivalid.read_only === "true"}
                          disabled={this.state.obj.uivalid.read_only === "true"}
                          maxLength={this.state.obj.uivalid.max_length}
                          onChange={(e) =>
                            this.state.formChangefn(
                              e.target.value,
                              this.state.obj.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              false,
                              this.state.obj,
                              outerIndex,
                              0,
                              "string"
                            )
                          }
                          onBlur={(e) =>
                            this.state.validationfn(
                              e.target.value,
                              this.state.obj.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              this.state.obj
                            )
                          }
                        />
                        <label
                          className="me-2"
                          style={{ alignContent: "center" }}
                        >
                          {" "}
                          :{" "}
                        </label>
                        <input
                          type="text"
                          name="child"
                          className="form-control formpadd me-2 my-1"
                          value={Object.values(objj)[0]}
                          readOnly={this.state.obj.uivalid.read_only === "true"}
                          disabled={this.state.obj.uivalid.read_only === "true"}
                          maxLength={this.state.obj.uivalid.max_length}
                          onChange={(e) =>
                            this.state.formChangefn(
                              e.target.value,
                              this.state.obj.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              false,
                              this.state.obj,
                              outerIndex,
                              1,
                              "string"
                            )
                          }
                          onBlur={(e) =>
                            this.state.validationfn(
                              e.target.value,
                              this.state.obj.index,
                              this.state.obj.name,
                              this.state.obj.type,
                              this.state.obj.id,
                              this.state.obj
                            )
                          }
                        />
                        {this.state.obj.uivalid.read_only !== "true" && (
                          <div>
                            {outerIndex === 0 ? (
                              <i
                                style={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa-solid fa-plus"
                                aria-hidden="true"
                                onClick={() => this.addInput(this.state.obj)}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa-solid fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.removeInput(outerIndex, this.state.obj)
                                }
                              ></i>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {/* <span className="field_hd">{obj.label.name}</span> */}
                  {/* <div style={{ display: "flex" }}>
                <select
                  className="form-control form-select formpadd "
                  aria-label="Default select example"
                  value={obj.value.id}
                  onChange={(e) => {
                    let id = 0;
                    let name = "";
                    for (let i = 0; i < obj.dep_choice.length; i++) {
                      if (obj.dep_choice[i].id === e.target.value) {
                        id = obj.dep_choice[i].id;
                        name = obj.dep_choice[i].name;
                      }
                    }
                    formChangefn(
                      name,
                      obj.index,
                      obj.name,
                      obj.type,
                      id,
                      false,
                      obj
                    );
                  }}
                  maxLength={obj.uivalid.max_length}
                  disabled={obj.uivalid.read_only === "true"}
                >
                  <option value="">None</option>

                  {obj.dep_choice.map((ch, chi) => (
                    <option key={chi} label={ch.name} value={ch.id}>
                      {ch.name}
                    </option>
                  ))}
                </select>
                {obj?.rcd_info === "true" && (
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      getSingleInfo(
                        e,
                        obj,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label.name,
                        obj.uivalid.read_only,
                        obj.depend_column
                      );
                    }}
                    style={{ marginLeft: "2px" }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </div>
                )}
              </div> */}
                </div>
              )}
              {this.state.obj.type === "date" ? (
                <div className=" form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label?.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      please verify your date!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="date"
                    className={
                      // this.state.obj.verified === "unverified"
                      //   ? "objpadd_tm unverifi"
                      //   : "objpadd_tm"
                       this.getCss(this.state.obj)
                    }
                    style={{ border: "1px solid #ced4da" }}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "time" ? (
                <div className=" form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                 {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label?.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      please verify your time!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate &&  (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="time"
                    step="1"
                    className={
                      // this.state.obj.verified === "unverified"
                      //   ? "objpadd_tm unverifi"
                      //   : "objpadd_tm"

                      this.getCss(this.state.obj)
                    }
                    style={{ border: "1px solid #ced4da" }}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "datetime" ? (
                <div className=" form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      please verify your datetime!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                    {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="datetime-local"
                    step="1"
                    className={
                      // this.state.obj.verified === "unverified"
                      //   ? "objpadd_tm unverifi"
                      //   : "objpadd_tm"
                       this.getCss(this.state.obj)
                    }
                    style={{ border: "1px solid #ced4da" }}
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "email" ? (
                <div className="form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                    {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label?.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your email!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                      {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="text"
                    className={
                         this.getCss(this.state.obj)
                    }
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "boolean" && !this.state.multiUpdate ? (
                <div
                  className={
                    this.state.multiUpdate ? "" : "form-check  fmcheck"
                  }
                >
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "true" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "false" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <input
                    type="checkbox"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "checkpadd unverifi"
                        : "checkpadd"
                    }
                    // value={this.state.obj.value === "true" ? true : false}
                    checked={this.state.obj.value === "true" ? true : false}
                    disabled={this.state.obj.uivalid.read_only === "true"}
                    onChange={(e) => {
                      this.state.formChangefn(
                        e.target.checked.toString(),
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      );
                    }}
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "boolean" && this.state.multiUpdate ? (
                <div
                  className={
                    this.state.multiUpdate ? "" : "form-check  fmcheck"
                  }
                >
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "true" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "false" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                  {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <select
                    /*    className="form-control form-select formpadd " */
                    className={this.getCss(this.state.obj)}
                    aria-label="Default select example"
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) => {
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      );
                    }}
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  >
                    {[
                      { name: "true", value: "True" },
                      { name: "false", value: "False" },
                    ].map((ch, chi) => (
                      <option key={chi} value={ch.name}>
                        {ch.value}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {this.state.obj.type === "password" ? (
                <div className="form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                     {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your password!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <input
                    type="password"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd "
                    }
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  />
                  {/* </input> */}
                </div>
              ) : null}
              {this.state.obj.type === "choice" &&
              this.state.obj.name !== "filter" ? (
                <div className="form-group ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "None" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    (this.state.obj.value === "None" ||
                      this.state.obj.value === "") && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                    {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label?.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label?.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <select
                    className={this.getCss(this.state.obj)}
                    aria-label="Default select example"
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                  >
                    {/* <option value="">{"None"}</option> */}
                    {this.state.obj.choice &&
                      this.state.obj.choice
                        .slice()
                        .sort((a, b) => a.value - b.value)
                        .map((ch, chi) => (
                          <option key={chi} value={ch.name}>
                            {ch.value}
                          </option>
                        ))}
                  </select>
                </div>
              ) : null}
              {this.state.obj.type === "reference" ? (
                <div>
                  <div className="form-group" /* style={ this.state.multiUpdate ?{ display: "flex" }:{}} */>
                    {this.state.obj.uivalid.mandatory === "true" && !this.state.multiUpdate &&
                      this.state.obj.value.value !== "" && (
                        <i
                          className="fa fa-asterisk mndtryfalse"
                          aria-hidden="true"
                        ></i>
                      )}
                    {this.state.obj.uivalid.mandatory === "true" && !this.state.multiUpdate &&
                      this.state.obj.uivalid.group_mandatory === "false" &&
                      this.state.obj.value.value === "" && (
                        <i
                          className="fa fa-asterisk mndtrytrue"
                          aria-hidden="true"
                        ></i>
                      )}

                    {this.state.obj.uivalid.mandatory === "true" && !this.state.multiUpdate &&
                      this.state.obj.uivalid.group_mandatory === "true" &&
                      this.state.obj.value.value === "" && (
                        <i
                          className="fa fa-asterisk mndtrytrue"
                          aria-hidden="true"
                        ></i>
                      )}
                    <span
                      id={"tiptar" + this.state.obj.index}
                      className="field_hd"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        this.state.m_rid.current = this.state.obj.id;
                        this.state.setContextMenu(
                          e.button,
                          e.nativeEvent.pageX,
                          e.nativeEvent.pageY
                        );
                      }}
                      // data-bs-custom-class="tooltip"
                      // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                    >
                    {!this.state.multiUpdate  && this.state.obj.label?.name}
                    </span>
                    {this.state.obj.label?.showTooltip === "true" && (
                      <UncontrolledTooltip
                        arrowClassName="tip  "
                        className="tip "
                        innerClassName="tip text-dark text-nowrap"
                        target={"tiptar" + this.state.obj.index}
                        placement="right"
                        style={{
                          whiteSpace: "normal",
                          maxWidth: "calc(100vw - 20px)",
                        }}
                      >
                        <pre
                          style={{
                            fontFamily: "sans-serif",
                            textAlign: "start",
                            margin: "0rem",
                            fontSize: "small",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <b>{this.state.obj.label?.tooltip}</b>
                        </pre>
                      </UncontrolledTooltip>
                    )}
                    {this.state.obj.verified === "unverified" && (
                      <div className="alert alert-danger form_alt" role="alert">
                        record not found!
                      </div>
                    )}
                    {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                      <div className="alert alert-danger form_alt" role="alert">
                       {this.state.obj?.verify_error}
                      </div>
                    )}
                    <div style={{ display: "flex" }}>
                      {this.state.multiUpdate && this.getUivalidCode()}
                      <input
                        type="text"
                        autoComplete="off"
                        className={
                          // this.state.obj.verified === "unverified"
                          //   ? "form-control formpadd formbor unverifi"
                          //   : "form-control formpadd formbor"
                          this.getCss(this.state.obj)
                        }
                        maxLength={this.state.obj.uivalid.max_length}
                        value={this.state.obj.value.value}
                        readOnly={this.state.obj.uivalid.read_only === "true"}
                        disabled={
                          this.state.obj.uivalid.read_only === "true"
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          this.state.formChangefn(
                            e.target.value,
                            this.state.index,
                            this.state.obj.name,
                            this.state.obj.type,
                            this.state.obj.id,
                            false,
                            this.state.obj.uivalid.read_only,
                            undefined,
                            undefined,
                            undefined,
                            this.state.obj,
                            this.state.obj.rcd_info
                          )
                        }
                        onBlur={(e) =>
                          setTimeout(() => {
                            if (this.state.obj.verified !== "verified") {
                              this.state.validationfn(
                                this.state.obj.value,
                                // e.target.value,
                                this.state.index,
                                this.state.obj.name,
                                this.state.obj.type,
                                this.state.obj.id,
                                this.state.obj
                              );
                            }
                          }, 500)
                        }
                        onClick={() => {
                          //this.showRecent()
                          this.state.showRecent?.(
                            this.state.obj,
                            this.state.index,
                            true
                          );
                        }}
                      />
                      {/* </input> */}

                      <div  
                        className="btnsrc vlpointer "
                        onClick={(e) =>
                          this.state.setcolumn(
                            this.state.index,
                            this.state.obj.name,
                            this.state.obj.type,
                            this.state.obj.id,
                            this.state.obj.uivalid.read_only,
                            this.state.obj
                          )
                        }
                      >
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                        ></i>
                      </div>
                      {this.state.obj.value.value &&
                        this.state.obj?.rcd_info === "true" &&
                        !this.state.multiUpdate && (
                          <div
                            className="btnsrc vlpointer"
                            onClick={(e) => {
                              this.state.getSingleInfo(
                                e,
                                this.state.obj,
                                this.state.obj.id,
                                this.state.obj.name,
                                this.state.obj.type,
                                this.state.obj.index,
                                this.state.obj.label.name,
                                this.state.obj.uivalid.read_only
                                // this.state.obj.depend_column
                              );
                            }}
                          >
                            <i
                              className="fa-solid fa-circle-info"
                              aria-hidden="true"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            ></i>
                          </div>
                        )}
                    </div>
                  </div>
                  {this.state.refrecord?.index === this.state.index &&
                    !this.state.multiUpdate &&
                    this.state.refrecord.record.length > 0 && (
                      <div className="sf_container">
                        {this.state.refrecord.record.map((obj_ref, or_i) => (
                          <div
                            className="refrcd"
                            onClick={(e) =>
                              this.state.setRefrecord(
                                obj_ref.value,
                                this.state.index,
                                this.state.obj.name,
                                this.state.obj.type,
                                obj_ref.id,
                                "",
                                this.state.obj
                              )
                            }
                            key={or_i}
                          >
                            {obj_ref.value}
                          </div>
                        ))}
                      </div>
                    )}

                  {!this.state.multiUpdate &&
                    this.state.obj.showRecent=== true && (
                      <div className="sf_container">
                        {this.state.obj.recentSearch.map((obj_ref, or_i) => (
                          <div
                            className="refrcd"
                            onClick={(e) =>
                              this.state.setRefrecord(
                                obj_ref.value,
                                this.state.obj.index,
                                this.state.obj.name,
                                this.state.obj.type,
                                obj_ref.id,
                                "recentSearch",
                                this.state.obj
                              )
                            }
                            key={or_i}
                          >
                            {obj_ref.value}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ) : null}
              {this.state.obj.type === "long_description" ? (
                <div>
                  <div className="form-group">
                    {this.state.obj.uivalid.mandatory === "true" &&
                      this.state.obj.value !== "" && (
                        <i
                          className="fa fa-asterisk mndtryfalse"
                          aria-hidden="true"
                        ></i>
                      )}
                    {this.state.obj.uivalid.mandatory === "true" &&
                      this.state.obj.value === "" && (
                        <i
                          className="fa fa-asterisk mndtrytrue"
                          aria-hidden="true"
                        ></i>
                      )}
                    <span
                      id={"tiptar" + this.state.obj.index}
                      className="field_hd"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        this.state.m_rid.current = this.state.obj.id;
                        this.state.setContextMenu(
                          e.button,
                          e.nativeEvent.pageX,
                          e.nativeEvent.pageY
                        );
                      }}
                      // data-bs-custom-class="tooltip"
                      // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                    >
                        {!this.state.multiUpdate  && this.state.obj.label?.name}
                    </span>
                    {this.state.obj.label.showTooltip === "true" && (
                      <UncontrolledTooltip
                        arrowClassName="tip  "
                        className="tip "
                        innerClassName="tip text-dark text-nowrap"
                        target={"tiptar" + this.state.obj.index}
                        placement="right"
                        style={{
                          whiteSpace: "normal",
                          maxWidth: "calc(100vw - 20px)",
                        }}
                      >
                        <pre
                          style={{
                            fontFamily: "sans-serif",
                            textAlign: "start",
                            margin: "0rem",
                            fontSize: "small",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          <b>{this.state.obj.label.tooltip}</b>
                        </pre>
                      </UncontrolledTooltip>
                    )}
                    {this.state.obj.verified === "unverified" && (
                      <div className="alert alert-danger form_alt" role="alert">
                        Please verify your filed!
                      </div>
                    )}
                    {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                      <div className="alert alert-danger form_alt" role="alert">
                        {this.state.obj?.verify_error}
                      </div>
                    )}
                    <div style={{ display: "flex" }}>
                      <textarea
                        name=""
                        id=""
                        type="text"
                        className={
                          this.state.obj.verified === "unverified"
                            ? "form-control formpadd formbor unverifi"
                            : "form-control formpadd formbor "
                        }
                        value={this.state.obj.value}
                        readOnly={this.state.obj.uivalid.read_only === "true"}
                        maxLength={this.state.obj.uivalid.max_length}
                        onChange={(e) =>
                          this.state.formChangefn(
                            e.target.value,
                            this.state.index,
                            this.state.obj.name,
                            this.state.obj.type,
                            this.state.obj.id,
                            false,
                            this.state.obj.uivalid.read_only,
                            undefined,
                            undefined,
                            undefined,
                            this.state.obj
                          )
                        }
                        onClick={(e) =>
                          this.state.validationfn(
                            e.target.value,
                            this.state.index,
                            this.state.obj.name,
                            this.state.obj.type,
                            this.state.obj.id,
                            this.state.obj
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "filter" ? (
                <div className="form-group objpdg ">
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                  {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>

                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}

                  <div className="filt_bdr">
                    <NewFilterCompo
                      showlist={this.state.showlist}
                      col_mn={this.state.col_mn}
                      col_depend={this.state.column_depend}
                      call_fil={(filtarray, in_index, col_id) =>
                        this.state.callfilter(
                          filtarray,
                          this.state.index,
                          in_index,
                          col_id
                        )
                      }
                      filtarray={this.state.filtarray}
                      timeline={this.props.timeline}
                      call_tm={(timeline) => this.state.calltimeline(timeline)}
                      loca={this.state.loca}
                      tabId={this.state.tabId}
                      isMobile={this.state.isMobile}
                    ></NewFilterCompo>
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "filter_ref" ? (
                <div className="form-group objpdg ">
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                     {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>

                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <div>
                    <NewFilterCompo
                      showlist={this.state.showlist}
                      col_mn={this.state.col_mn_ref}
                      col_depend={this.state.column_depend}
                      call_fil={(filtarray, in_index, col_id) =>
                        this.state.callfilter(
                          filtarray,
                          this.state.index,
                          in_index,
                          col_id
                        )
                      }
                      // setFiltArray={this.state.setref_filter}
                      filtarray={this.state.setref_filter}
                      timeline={this.props.timeline}
                      call_tm={(timeline) => this.state.calltimeline(timeline)}
                      loca={this.state.loca}
                      tabId={this.state.tabId}
                      isMobile={this.state.isMobile}
                    ></NewFilterCompo>
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "other_table_filter" ? (
                <div className="form-group objpdg ">
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                     {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <div>
                    <NewFilterCompo
                      showlist={this.state.showlist}
                      col_mn={this.state.column_other}
                      col_depend={this.state.column_depend}
                      call_fil={(filtarray, in_index, col_id) =>
                        this.state.callfilter(
                          filtarray,
                          this.state.index,
                          in_index,
                          col_id
                        )
                      }
                      filtarray={this.state.filtarray}
                      timeline={this.props.timeline}
                      call_tm={(timeline) => this.state.calltimeline(timeline)}
                      loca={this.state.loca}
                      tabId={this.state.tabId}
                      isMobile={this.state.isMobile}
                    ></NewFilterCompo>
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "filter_script" ? (
                <div className="form-group objpdg ">
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <div>
                    <NewFilterCompo
                      showlist={this.state.showlist}
                      col_mn={this.state.col_mn}
                      col_depend={this.state.column_depend}
                      call_fil={(filtarray, in_index, col_id) =>
                        this.state.callfilter(
                          filtarray,
                          this.state.index,
                          in_index,
                          col_id
                        )
                      }
                      filtarray={this.state.obj.value.filter}
                      // timeline={this.state.timeline}
                      // setTimeLine={(timeline) =>
                      //   this.state.calltimeline(timeline)
                      // }
                      setScript={(script) =>
                        this.state.callScript(
                          script,
                          this.state.obj.this.state.index
                        )
                      }
                      isScript={true}
                      script={this.state.reScript}
                      selectedScript={this.state.obj.value.script}
                      loca={this.state.loca}
                    ></NewFilterCompo>
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "JSONObject" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                 {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your charecter not except number!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                    {this.state.obj?.verify_error}
                    </div>
                  )}
                  <textarea
                    type="text"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd areaheight"
                    }
                    value={JSON.stringify(this.state.obj.value)}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  ></textarea>
                </div>
              ) : null}
              {this.state.obj.type === "html" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your charecter not except number!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <div>
                    <JoditEditor
                      ref={this.state.editor}
                      value={this.state.obj.value}
                      /*   config={{readonly:false}} */
                      onChange={(content) =>
                        this.state.formChangefn(
                          content,
                          this.state.index,
                          this.state.obj.name,
                          this.state.obj.type,
                          this.state.obj.id,
                          false,
                          this.state.obj.uivalid.read_only,
                          undefined,
                          undefined,
                          undefined,
                          this.state.obj
                        )
                      }
                    />
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "multi_line_text" ? (
                <div className="form-group objpdg ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value === "" && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                      {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your charecter not except number!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                     {this.state.obj?.verify_error}
                    </div>
                  )}
                  <textarea
                    type="text"
                    className={
                      this.state.obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd areaheight"
                    }
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) =>
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      )
                    }
                    onBlur={(e) =>
                      this.state.validationfn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        this.state.obj
                      )
                    }
                  ></textarea>
                </div>
              ) : null}
              {this.state.obj.type === "lookup" ? (
                <div className="form-group">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "None" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    (this.state.obj.value === "None" ||
                      this.state.obj.value === "") && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                 {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <select
                    className="form-control form-select formpadd "
                    aria-label="Default select example"
                    value={this.state.obj.value}
                    onChange={(e) => {
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      );
                    }}
                    maxLength={this.state.obj.uivalid.max_length}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                  >
                    <option value="None">None</option>
                    {this.state.obj.lookup.map((ch, chi) => (
                      <option key={chi} value={ch.name}>
                        {ch.value}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {this.state.obj.type === "depend_table" ? (
                <div className="form-group ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "None" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    (this.state.obj.value === "None" ||
                      this.state.obj.value === "") && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                     {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <select
                    className="form-control form-select formpadd "
                    aria-label="Default select example"
                    value={this.state.obj.value}
                    readOnly={this.state.obj.uivalid.read_only === "true"}
                    maxLength={this.state.obj.uivalid.max_length}
                    onChange={(e) => {
                      // this.state.onSelection(e.target.value);
                      this.state.formChangefn(
                        e.target.value,
                        this.state.index,
                        this.state.obj.name,
                        this.state.obj.type,
                        this.state.obj.id,
                        false,
                        this.state.obj.uivalid.read_only,
                        undefined,
                        undefined,
                        undefined,
                        this.state.obj
                      );
                    }}
                  >
                    <option value="None">None</option>
                    {this.state.choice_mn.map((ch, chi) => (
                      <option key={chi} value={ch.name}>
                        {ch.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {this.state.obj.type === "multi_select" ? (
                <div className="form-group ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value.record.length !== 0 && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value.record.length === 0 && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  {/* {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value.record.length !== 0 && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "false" &&
                    this.state.obj.value.record.length === 0 && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.uivalid.group_mandatory === "true" &&
                    this.state.obj.value.record.length === 0 && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )} */}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                 {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  <div>
                    <MultipleSelectCompo
                      record={this.state.record}
                      list={this.state.obj?.value}
                      id={this.state.obj.id}
                      ind={this.state.obj.index}
                      loca={this.state.loca}
                      type={this.state.obj.type}
                      tabname={this.state.tabname}
                      isMobile={this.state.isMobile}
                      readOnly={this.state.obj.uivalid.read_only}
                      setMSC={(val) => {
                        //  funName?.()
                        this.state?.setMSC?.(
                          val,
                          this.state.obj.index,
                          this.state.obj.name,
                          this.state.obj.type,
                          this.state.obj.id
                        );
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "group_key_value" ? (
                <div className="form-group objpdg">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    !this.state.obj.value.properties
                      .map((obj) => [
                        obj.name,
                        ...obj.choice.map((choice) => choice.value),
                      ])
                      .flat()
                      .some((value) => value === "") && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value.properties
                      .map((obj) => [
                        obj.name,
                        ...obj.choice.map((choice) => choice.value),
                      ])
                      .flat()
                      .some((value) => value === "") && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                   {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}

                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your character not accept number!
                    </div>
                  )}
                  {this.state.obj.verified === "min_unverified" && !this.state.multiUpdate && (
                    <div className="alert alert-danger form_alt" role="alert">
                      {this.state.obj?.verify_error}
                    </div>
                  )}

                  {/* Your existing code */}
                  <div style={{ padding: "2px 15px", color: "black" }}>
                    {this.state.obj.value.properties.map(
                      (parent, parentIndex) => (
                        <div key={parentIndex} className="d-flex flex-column">
                          <label className="me-2">Name:</label>
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              name="child"
                              className="form-control formpadd me-2 my-1"
                              value={parent.name}
                              readOnly={
                                this.state.obj.uivalid.read_only === "true"
                              }
                              maxLength={this.state.obj.uivalid.max_length}
                              onChange={(e) =>
                                this.state.formChangefn(
                                  e.target.value,
                                  this.state.index,
                                  this.state.obj.name,
                                  this.state.obj.type,
                                  this.state.obj.id,
                                  false,
                                  this.state.obj.uivalid.read_only,
                                  parentIndex,
                                  null,
                                  "name",
                                  this.state.obj
                                )
                              }
                              onBlur={(e) =>
                                this.state.validationfn(
                                  e.target.value,
                                  this.state.obj.index,
                                  this.state.obj.name,
                                  this.state.obj.type,
                                  this.state.obj.id,
                                  this.state.obj
                                )
                              }
                            />

                            {parentIndex === 0 ? (
                              <i
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa fa-solid fa-plus"
                                aria-hidden="true"
                                onClick={() => this.handleAddParentInput()}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa fa-solid fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.handleRemoveParentInput(parentIndex)
                                }
                              ></i>
                            )}
                          </div>
                          <div className="d-flex flex-column">
                            <label>Choices:</label>
                            {parent.choice.map((child, childIndex) => (
                              <div className="d-flex">
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    name="child"
                                    className="form-control formpadd me-2 my-1"
                                    value={child.value}
                                    readOnly={
                                      this.state.obj.uivalid.read_only ===
                                      "true"
                                    }
                                    maxLength={
                                      this.state.obj.uivalid.max_length
                                    }
                                    onChange={(e) =>
                                      this.state.formChangefn(
                                        e.target.value,
                                        this.state.index,
                                        this.state.obj.name,
                                        this.state.obj.type,
                                        this.state.obj.id,
                                        false,
                                        this.state.obj.uivalid.read_only,
                                        parentIndex,
                                        childIndex,
                                        "choice",
                                        this.state.obj
                                      )
                                    }
                                    onBlur={(e) =>
                                      this.state.validationfn(
                                        e.target.value,
                                        this.state.obj.index,
                                        this.state.obj.name,
                                        this.state.obj.type,
                                        this.state.obj.id,
                                        this.state.obj
                                      )
                                    }
                                  />
                                  {childIndex === 0 ? (
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                      }}
                                      className="fa fa-solid fa-plus"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.handleAddChildInput(parentIndex)
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        marginTop: "9px",
                                      }}
                                      className="fa fa-solid fa-minus"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.handleRemoveChildInput(
                                          parentIndex,
                                          childIndex
                                        )
                                      }
                                    ></i>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "key_value" &&
              this.state.obj.value.properties.length > 0 ? (
                <div className="form-group objpdg">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    !this.state.keyValueJson.properties.some((obj) =>
                      Object.values(obj).includes("")
                    ) && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.keyValueJson.properties.some((obj) =>
                      Object.values(obj).includes("")
                    ) && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}

                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}

                  {this.state.obj.verified === "unverified" && (
                    <div className="alert alert-danger form_alt" role="alert">
                      Please verify your character not accept number!
                    </div>
                  )}

                  <div style={{ padding: "2px 15px" }}>
                    {this.state.obj.value.properties.map((data, index) => (
                      <div key={index} className="d-flex flex-column">
                        <div className="d-flex flex-column">
                          <div className="field_hd text-capitalize">
                            {data.name}:
                          </div>
                          <div className="d-flex">
                            {data.choice &&
                              data.choice.map((d, ind) => (
                                <div
                                  className="form-control my-2 ms-1 "
                                  style={{ width: "auto", fontSize: "18px" }}
                                >
                                  <label key={d.value}>
                                    <input
                                      className="me-2"
                                      type="checkbox"
                                      checked={
                                        this.state.keyValueJson.properties[
                                          index
                                        ] &&
                                        this.state.keyValueJson.properties[
                                          index
                                        ].value === d.value
                                      }
                                      value={d.value}
                                      readOnly={
                                        this.state.obj.uivalid.read_only ===
                                        "true"
                                      }
                                      maxLength={
                                        this.state.obj.uivalid.max_length
                                      }
                                      onChange={(e) =>
                                        this.state.formChangefn(
                                          e.target.value,
                                          this.state.index,
                                          this.state.obj.name,
                                          this.state.obj.type,
                                          this.state.obj.id,
                                          false,
                                          this.state.obj.uivalid.read_only,
                                          index,
                                          ind,
                                          data.name,
                                          this.state.obj
                                        )
                                      }
                                      onBlur={(e) =>
                                        this.state.validationfn(
                                          e.target.value,
                                          this.state.obj.index,
                                          this.state.obj.name,
                                          this.state.obj.type,
                                          this.state.obj.id,
                                          this.state.obj
                                        )
                                      }
                                    />
                                    {d.value}
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {this.state.obj.type === "label" ? (
                <div>
                  <span className="field_hd">   {!this.state.multiUpdate  && this.state.obj.label?.name}</span>
                </div>
              ) : null}
              {this.state.obj.type === "image_upload" ? (
                <div className="form-group ">
                  {this.state.obj.uivalid.mandatory === "true" &&
                    this.state.obj.value !== "None" &&
                    this.state.obj.value !== "" && (
                      <i
                        className="fa fa-asterisk mndtryfalse"
                        aria-hidden="true"
                      ></i>
                    )}

                  {this.state.obj.uivalid.mandatory === "true" &&
                    (this.state.obj.value === "None" ||
                      this.state.obj.value === "") && (
                      <i
                        className="fa fa-asterisk mndtrytrue"
                        aria-hidden="true"
                      ></i>
                    )}
                  <span
                    id={"tiptar" + this.state.obj.index}
                    className="field_hd"
                    onContextMenu={(e) => {
                      e.preventDefault();
                      this.state.m_rid.current = this.state.obj.id;
                      this.state.setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }}
                    // data-bs-custom-class="tooltip"
                    // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                  >
                  {!this.state.multiUpdate  && this.state.obj.label?.name}
                  </span>
                  {this.state.obj.label.showTooltip === "true" && (
                    <UncontrolledTooltip
                      arrowClassName="tip  "
                      className="tip "
                      innerClassName="tip text-dark text-nowrap"
                      target={"tiptar" + this.state.obj.index}
                      placement="right"
                      style={{
                        whiteSpace: "normal",
                        maxWidth: "calc(100vw - 20px)",
                      }}
                    >
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          textAlign: "start",
                          margin: "0rem",
                          fontSize: "small",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <b>{this.state.obj.label.tooltip}</b>
                      </pre>
                    </UncontrolledTooltip>
                  )}
                  {this.state.obj.label.showHelper === "true" && (
                    <div className="alert alert-warning">
                      <pre
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontWeight: "bold",
                          margin: "0rem",
                          color: "black",
                        }}
                      >
                        {this.state.obj.label.helper}
                      </pre>
                    </div>
                  )}
                  {this.state.obj.verified === "unverified" && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                      style={{
                        padding: "0.2rem 0.2rem",
                        marginBottom: "0px",
                      }}
                    >
                      Please verify your charecter not accept number!
                    </div>
                  )}
                  <div className="my-2">
                    {this.state.obj.value !== "" ? (
                      <div className={form["saved-imgs"]}>
                        <div className="rr-img-div">
                          <div
                            className={form["cancel-mark-div"]}
                            onClick={() => {
                              this.props.deleteImage(this.state.obj);
                            }}
                          >
                            <i
                              className="fa-solid fa-xmark fa-lg"
                              style={{ color: "red", cursor: "pointer" }}
                            ></i>
                          </div>
                          <img
                            className={form["user-feedback-img"]}
                            src={
                              this.state.obj.localimg
                                ? this.state.obj.value
                                : this.state.imageloca + this.state.obj.value
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        id="add-image-for-preview"
                        className={`${form["add-img-div"]} rr-img-div`}
                        onClick={this.props.launchChoose}
                      >
                        <div className="my-2">
                          <i className="fa-solid fa-plus fa-2xl"></i>
                        </div>
                        <div>Add Image</div>
                        <input
                          className={"ourCustomized"}
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) =>
                            this.props.handleFileChange(e, this.state.obj)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )}
      </div>
    );
  }
}

export default FormInnerComponent;
