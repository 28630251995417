import React from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const WithNavigate = (Component) => {
    const WithNavigateComponent  = (props) => {
        const navigate = useNavigate();
        const location = useLocation();
        

       /*  console.log('WithNavigate: location', location); */
        const navigateWithSearchParams = (pathname, params, state) => {
          navigate({
            pathname,
            search: createSearchParams(params).toString(),
          }, { state });
        };
        return <Component {...props} navigate={navigate} navigateWithSearchParams={navigateWithSearchParams} location={location} />;
      };
      return WithNavigateComponent;
  };

export default WithNavigate;
