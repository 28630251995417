import axios from "axios";
import React, { Component } from "react";
import { ListGroupItem } from "react-bootstrap";
import "../css/userNotification.css";
import WithNavigate from "./withNavigate";

class UserNotification extends Component {
  state = {
    msg: this.props.msg,
    cnt: this.props.cnt,
    loading: false,
    loca: this.props.loca,
    records: [],
  };

  constructor(props) {
    super(props);
    this.goToRecord = this.goToRecord.bind(this);
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    this.setState({ loading: true, cnt: 0 });
    this.props.showNotificationCompo(this.state.cnt);
    let jo = { messages: this.state.msg };
    axios
      .post(this.state.loca + "/loom/set/seen", jo, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          this.setState({
            loading: false,
            // msg: result.notification.message,
            // cnt: result.notification.count,
          });
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  goToRecord(record, tabname) {
    if (tabname !== null && record !== null) {
      const { navigateWithSearchParams } = this.props;
      this.props.context.handleNotificationCompo();
      navigateWithSearchParams(
        "/private/form",
        {
          rty: "record",
          tableName: tabname,
          rid: record,
        },
        {}
      );

      // this.props.showFormCompo(tabname, record, "record")
    }
  }

  callNotiFnRecord = () => {
    const { navigateWithSearchParams } = this.props;

    navigateWithSearchParams(
      "/private/list",
      {
        rty: "recordList",
        tableName: "user_notification",
      },
      {}
    );

    this.props.showNotificationCompo(false, "false");
  };

  render() {
    return (
      <div style={{ flexGrow: 1 }} className="usernoti_outerline">
        {this.state.msg?.length > 0 &&
          this.state.msg.map((obj, index) => (
            <ListGroupItem
              action
              key={index}
              onClick={(e) => this.goToRecord(obj.record, obj.loomtable)}
              className="message-item"
              style={{ cursor: "pointer", transition: "color 0.1s" }}
            >
              <div
                style={{ textWrap: "wrap" }}
                dangerouslySetInnerHTML={{ __html: obj.message }}
              ></div>
            </ListGroupItem>
          ))}

        {/* {this.state.msg?.length === 5 &&  <div
          className=""
          style={{
            textAlign: "end",
            display: "inline-flex",
            width: "100%",
           
            justifyContent: "end",
            marginTop:"0.5em",
          }}
        >
          <p>
            <a className="link-opacity-100 "   onClick={this.callNotiFnRecord}>
              See All Notification
            </a>
          </p>
        </div>} */}

        <div
          className=""
          style={{
            textAlign: "end",
            display: "inline-flex",
            width: "100%",

            justifyContent: "end",
            marginTop: "0.5em",
          }}
        >
          <p>
            <a className="link-opacity-100 " onClick={this.callNotiFnRecord}>
              See All Notification
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default WithNavigate(UserNotification);
