import React, { Component } from "react";
import ClientInfoCompo from "./component/clientInfoCompo";
import { AppConfig } from "./Utility/AppConfig";

export class ApForm extends Component {
  _isMounted = false;
  state = {
    record: [],
    sr: null,
    showClientInfo: false,
    filter: {
      filter: [
        {
          co: "",
          cl: "",
          mc: "",
          an: "",
          ct: "",
          af: "",
          rf: { id: "", value: "" },
        },
      ],
    },
    //  loca: "https://apis.loomyarn.com",
    loca: AppConfig.loca,
  };

  constructor(record, setRecord, formCompo, multilInsert) {
    super(record);
    this.formCompo = formCompo;
    this.multilInsert = multilInsert;
    this.state.record = record;
    this.state.sr = setRecord;
    this.getFieldValue = this.getFieldValue.bind(this);
    this.getMultiFieldValue = this.getMultiFieldValue.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.setMultiFieldValue = this.setMultiFieldValue.bind(this);
    this.setMandatory = this.setMandatory.bind(this);
    this.isMandatory = this.isMandatory.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.setReadOnly = this.setReadOnly.bind(this);
    this.getReadOnly = this.getReadOnly.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.removeAllOption = this.removeAllOption.bind(this);
    // this.openGride = this.openGride.bind(this);
    this._isMounted = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = null;
  }

  getFieldValue(name) {
    let res = this.formCompo.getFieldValueOfUiScript(name, this.state.record);

    return res;
  }
  
  setErrorOnForm = (err, isErr) => {
    this.formCompo.setErrorFromApForm(err, isErr);
  };

  getMultiFieldValue(name, index) {
    let res = this.multilInsert?.getFieldValueOfUiScript(
      name,
      index,
      this.state.record
    );

    return res;
  }

  setFieldValue(name, value, id) {
    console.log("2apForm" , this.state.record);
    
    this.formCompo.setFieldValueOfUiScript(name, value, id, this.state.record);
  }
  getLoca = () => {
    return this.state.loca;
  };

  setMultiFieldValue(name, value, index, id) {
    this.multilInsert.setFieldValueOfUiScript(
      name,
      value,
      index,
      id,
      this.state.record
    );
  }

  setErrorOnMultiInsertForm = (err, isErr) => {
    console.log("setttadsfads");
    this.multilInsert.setErrorFromApForm(err, isErr);
  };

  setMandatory(name, value) {
    this.formCompo.setMandatoryOfUiScript(name, value, this.state.record);
  }

  isMandatory(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        let val = frecord[i].uivalid.mandatory;
        if (val === "true") {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  // setVisible(name, value) {
  //   const frecord = this.state.record;
  //   console.log("frecord" ,frecord);
    
  //   for (let i = 0; i < frecord.length; i++) {
  //     if (
  //       name === "id" ||
  //       name === "created" ||
  //       name === "created_by" ||
  //       name === "updated" ||
  //       name === "updated_by"
  //     ) {
  //       break;
  //     }
  //     if (frecord[i].name === name) {
  //       console.log("145" ,frecord[i].name );
        
  //       frecord[i].uivalid.visible = value;
  //       let type = frecord[i].type;
  //       if (value === "false") {
  //         if (type === "filter") {
  //           this.setFieldValue(name, {
  //             filter: [
  //               {
  //                 co: "",
  //                 cl: "",
  //                 mc: "",
  //                 an: "",
  //                 ct: "",
  //                 af: "",
  //                 rf: { id: "", value: "" },
  //                 dc: { id: "", value: "" },
  //                 ch: [],
  //               },
  //             ],
  //             timeline: "",
  //           });
  //         } else if (type === "boolean") {
  //           this.setFieldValue(name, "false");
  //         } else if (type === "multi_select") {
  //           this.setFieldValue(name, { record: [] });
  //         } else {
  //           this.setFieldValue(name, "");
  //         }
  //       }
  //       if (this._isMounted) {
  //         this.setState({ sr: frecord });
  //       }
  //     }
  //   }
  // }

    setVisible(name ,value){
      
      this.formCompo.setVisibleOfUiScript(name, value, this.state.record);
    }

  setReadOnly(name, value) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.read_only = value;
        if (this._isMounted) {
          this.setState({ record: frecord });
        }
      }
    }
  }

  getReadOnly(name) {
    let res = this.formCompo.getReadonlyOfUiScript(name, this.state.record);
    return res;
  }

  setFilter(value, field, op) {
    const frecord = this.state.record;
    let filt = "";
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === field) {
        let type = frecord[i].type;
        filt =
          '{"co": "' +
          field +
          '", "cl": "", "mc": "' +
          op +
          '","an": "' +
          value +
          '","ct": "' +
          type +
          '","af": ""}';
        break;
      }
    }
    return JSON.parse(filt);
  }

  addOption(value, label, name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice.push({ value: value, label: label });
        break;
      }
    }
  }

  removeOption(label, name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        for (let j = 0; j < frecord[i].choice.length; j++) {
          if (frecord[i].choice[j].label === label) {
            frecord[i].choice.splice(j, 1);
            break;
          }
        }
        break;
      }
    }
  }

  removeAllOption(name) {
    const frecord = this.state.record;
    for (let i = 0; i < frecord.length; i++) {
      if (
        // name === "uni_id" ||
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name && frecord[i].type === "choice") {
        frecord[i].choice = [];
        break;
      }
    }
  }

  openGrid(val) {
    let filter = {
      filter: [
        {
          co: "id",
          cl: "Id",
          mc: "=",
          an: val,
          ct: "int",
          af: "",
          rf: { id: "", value: "" },
        },
      ],
    };
    // this.setState({ showClientInfo: true, filter: filter });
    return filter;
  }

  redirectPage = (url, params, body) => {
    if (url) {
      let res = this.formCompo.redirectPage(url, params, body);
    }
  };

  render() {
    return (
      <div>
        {this.state.showClientInfo === true && (
          <ClientInfoCompo
            filter={this.state.filter}
            loca={this.state.loca}
          ></ClientInfoCompo>
        )}
      </div>
    );
  }
}

export default ApForm;
