import React, { Component } from "react";
import "../css/ownerPreference.css";
import "../css/mainCompo.css";
import axios from "axios";
import { Button, Modal as Mod } from "react-bootstrap";
import Modal from "react-modal";
import { Outlet } from "react-router-dom";
import LeftNav from "./left_navigation";
import { ToastContainer } from "react-toastify";
import GlobalContext from "./GlobalContext ";
import WithNavigate from "./withNavigate";
import UserNotificationCompo from "./userNotification";
import ImpersonationCompo from "./impersonationCompo";
import { isMobile } from "react-device-detect";
class PrivateCompo extends Component {
  static contextType = GlobalContext;

  state = {
    /*   col_mn: [{}],
      tab_mn: [{}],
      listName: "",
      tabState: false,
      colState: false,
      loading: false,
      page_error: false,
      error: "", */
    height: "800px",
    loca: this.props.loca,
    showLeftPane: this.props.showLeftPane,
    /* userinfo:this.props.userinfo, */
    isMobile: this.props.isMobile,
    /*   showLeftPane:false, */
    userinfo: this.props.userinfo,
    homePage: {},
    headHeight: 0,
    openSnackBar: false,
    snacKbarMsg: "",
    severity: "",
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.mainTop = React.createRef();
    this.verifyied = React.createRef();

    /*  this.formChangefn = this.formChangefn.bind(this);
     
    tthis.handleClose = this.handleClose.bind(this); */
  }

  componentDidMount() {
    this.verifyied.current = localStorage.getItem("token");
    if (window.innerWidth <= 768) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    if (this.verifyied.current == null) {
      this.props.navigate("/");
    } else {
      const { showLeftPane, userinfo } = this.context;
      this.setState({ userinfo: userinfo });
      setTimeout(() => {
        this.getWindowDimensions();
      }, 100);
    }
  }

  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    // var ht = document.getElementById("maintop").clientHeight;
    var ht = document.getElementById("maintop")?.getBoundingClientRect().height;
    var hh = height - ht; //window.innerHeight - height; height +
    var wd = width;
    var mobile = false;
    var leftpane = false;
    if (wd < 768) {
      mobile = true;
    }
    if (!mobile) {
      leftpane = true;
    }
    this.setState({
      height: hh + "px",
      width: wd,
      showMain: true,
      headHeight: ht,
    });
    this.setLeftPan(leftpane);
    this.props.setIsMobile(mobile);
  };

  refreshPage = () => {
    window.location.reload();
  };

  setLeftPan = (val) => {
    this.props.handleLeftPane(val);
  };

  setLeftpan2 = () => {
    this.props.handleLeftPane(!this.state.showLeftPane);
  };

  callhome = (homepage) => {
    this.setState({ homePage: homepage }, () => {
      const { navigateWithSearchParams } = this.props;
      if (this.state.homePage !== undefined) {
        var homeType = this.state.homePage.type;
        if (homeType === "dashboard") {
          navigateWithSearchParams("/private/dashboard", {}, {});
          document.title = "Dashboard";
          if (isMobile) {
            this.setLeftPan(this.state.showLeftPane);
          }
        } else if (homeType === "report") {
          var rt_name = this.context.landingPref.report.value;
          this.showReportCompo(rt_name);
          this.showReportCompoNew();
        } else if (homeType === "checklist") {
          this.showChecklistCompo();
        } else if (homeType === "uipage") {
          navigateWithSearchParams("/private/ui-page", {}, {});
          document.title = "Ui page";
        } else {
        }
      } else {
      }
    });
  };

  setImp = (rel) => {
    this.props.navigate("/private");
    window.location.reload(false);
  };

  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false, severity: "", snacKbarMsg: "" });
  };

  handleOpenSnackBar = (severity, snacKbarMsg) => {
    this.setState({
      openSnackBar: true,
      severity: severity,
      snacKbarMsg: snacKbarMsg,
    });
  };

  sendLandingPref = (lanPref) => {
    this.setState({ landingPref: lanPref });
    // this.context.setLandingPref(lanPref)
    if (lanPref !== undefined) {
      var lan_type = this.state.landingPref.type;
      if (lan_type === "dashboard") {
        // this.showDashboard();
      } else if (lan_type === "report") {
        var rt_name = this.context.landingPref.report.value;
        // this.showReportCompo(rt_name);
        this.showReportCompoNew(rt_name);
      } else if (lan_type === "checklist") {
        this.showChecklistCompo();
      } else if (lan_type === "uipage") {
        // this.props.showUiPageCompo("initial");
      } else {
        // this.showDashboard();
      }
    } else {
      // this.showDashboard();
    }
  };
  deActiveExpoToken = () => {
    var token = localStorage.getItem("token");
    let et = localStorage.getItem("expoToken");
    let did = localStorage.getItem("deviceId");
    // let us = localStorage.getItem("userDetails");
    var us = JSON.parse(localStorage.getItem("userDetails"));
    if (et && did) {
      axios
        .post(
          this.state.loca + "/loom/logout/expotoken",
          { expoToken: et, deviceId: did, username: us.username },
          {
            headers: {
              "Content-type": "application/json",
              authorization: "Bearer" + token,
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  logout = () => {
    document.title = "Loomweb";
    if (window.ReactNativeWebView) {
      this.deActiveExpoToken();
    }
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("tableName");
    this.context.closeModal();
    this.context.setAuthenticated(2);
    this.props.navigate("/");
  };

  /*   handleLeftPane=()=> {
    this.setState({ showLeftPane: !this.context.showLeftPane });
   } */

  getClassName = () => {
    const { showLeftPane, isMobile } = this.context;
    if (showLeftPane && !isMobile && window.innerWidth > 1100) {
      return "cl_small";
    } else if (showLeftPane && isMobile) {
      return "cl_small_mob";
    } else if (showLeftPane && !isMobile && window.innerWidth > 767) {
      return "cl_tab";
    } else {
      return "cl_no";
    }
  };

  getClassName2 = () => {
    // const {  } = this.state;
    const { showLeftPane, isMobile } = this.context;
    // const screenWidth = window.screen.width;
    if (showLeftPane && !isMobile && window.innerWidth > 1100) {
      return "cl_big";
    } else if (showLeftPane && isMobile) {
      return "cl_yes_mob g-blur";
    } else if (showLeftPane && !isMobile && window.innerWidth > 767) {
      return "cl_yes_tab";
    } else {
      return "";
    }
  };

  handleClick = () => {
    if (isMobile) {
      this.setLeftPan(this.state.showLeftPane);
    }
  };
  handleClick2 = () => {
    if (isMobile) {
      this.setLeftPan(false);
    }
  };

  render() {
    const { isImpersonate } = this.context;
    const backgroundClass = isImpersonate
      ? "background-red"
      : "background-blue";
    let abc = localStorage.getItem("token");

    // if(!(localStorage.getItem("token"))){
    //   this.props.navigate("/login");
    // /*   return(<div></div>)  */
    // }

    const currentPath = window.location.pathname;

    if (!localStorage.getItem("token") && currentPath !== "/login") {
      window.location.href = "/login";
    }

    return (
      <>
        {/*      <div>{window.innerHeight}</div>
        <div>{window.innerWidth}</div> */}
        <div>
          {this.verifyied.current != null && (
            <div ref={this.myRef} id="partop">
              <ToastContainer
                toastStyle={{ marginTop: "2vh" }}
                position="top-center"
                autoClose={1800}
              />
              <div className="mainTopBar" id="maintop" ref={this.mainTop}>
                {!this.state.showlogin && (
                  <div>
                    {isMobile ? (
                      <div
                        className={`disfl mainbgcolor  ${backgroundClass} gutter main-div`}
                      >
                        <div className="fl1 no_pad">
                          <div
                            className="marleft10"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <span className="homeicon">
                              <span>
                                <i
                                  className="fa fa-home home_top"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Home"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.callhome(this.state.landingPref)
                                  }
                                  style={{
                                    top: "0px",
                                  }}
                                ></i>
                              </span>
                            </span>

                            {window.ReactNativeWebView && (
                              <span style={{ color: "white" }}>
                                <i
                                  className="fa fa-refresh"
                                  aria-hidden="true"
                                  title="Refresh"
                                  onClick={this.refreshPage}
                                ></i>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="fl ">
                          <div className="rig mx-2">
                            {this.context.userinfo.admin === "true" ? (
                              <span className="u-name text-capitalize">
                                {this.context.impersonate_name}
                              </span>
                            ) : (
                              <span className="u-name text-capitalize">
                                {this.context.userinfo.name}
                              </span>
                            )}
                            <span className="usericon">
                              <i
                                className="fa fa-user-circle-o"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Profile"
                                onClick={this.context.openModal}
                              ></i>
                            </span>
                            <span className="usericon">
                              <i
                                className="fa fa-bell"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Notification"
                                aria-hidden="true"
                                onClick={this.context.showNotificationCompo}
                              >
                                {this.context.cnt > 0 && (
                                  <span className="span-cnt">
                                    {this.context.cnt}
                                  </span>
                                )}
                              </i>
                            </span>
                            {this.context.userinfo.admin === "true" && (
                              <span className="usericon">
                                <i
                                  className="fa fa-bars"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Impersonate"
                                  aria-hidden="true"
                                  onClick={this.context.openList}
                                ></i>
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-2 no_pad">
                <input
                  type="button"
                  className="btn btn-danger"
                  value="Logout"
                  onClick={this.logout}
                  style={{ padding: "0px 5px" }}
                ></input>
              </div> */}
                      </div>
                    ) : (
                      // <div className="disfl mainbgcolor gutter">
                      <div
                        className={`disfl mainbgcolor ${backgroundClass} gutter`}
                      >
                        <div className="">
                          {/* <div className="marleft10"> */}
                          <div style={{ margin: "0px 30px" }}>
                            <div className="homeicon">
                              <span
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                              >
                                <span>
                                  <i
                                    className="fa fa-home home_top"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Home"
                                    aria-hidden="true"
                                    onClick={() =>
                                      this.callhome(this.state.landingPref)
                                    }
                                  ></i>
                                </span>
                                <p
                                  className="loom_text"
                                  onClick={() =>
                                    this.callhome(this.state.landingPref)
                                  }
                                >
                                  Loomweb
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="fl2">
                          <div className="rig mx-4">
                            {this.context.userinfo.admin === "true" ? (
                              <span className="u-name text-capitalize">
                                {this.context.impersonate_name}
                              </span>
                            ) : (
                              <span className="u-name text-capitalize">
                                {this.context.userinfo.name}
                              </span>
                            )}
                            <span className="usericon">
                              <i
                                className="fa fa-user-circle-o"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Profile"
                                onClick={this.context.openModal}
                              ></i>
                            </span>
                            <span className="usericon">
                              <i
                                className="fa fa-bell"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Notification"
                                onClick={this.context.showNotificationCompo}
                                // onClick={this.showListCompo}
                              >
                                {this.context.cnt > 0 && (
                                  <span className="span-cnt">
                                    {this.context.cnt}
                                  </span>
                                )}
                              </i>
                            </span>
                            {this.context.userinfo.admin === "true" && (
                              <span className="usericon">
                                <i
                                  className="fa fa-bars"
                                  aria-hidden="true"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Impersonate"
                                  onClick={this.context.openList}
                                ></i>
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-2 no_pad">
                <input
                  type="button"
                  className="btn btn-danger"
                  value="Logout"
                  onClick={this.logout}
                  style={{ padding: "0px 5px" }}
                ></input>
              </div> */}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className={this.context.showLeftPane ? "" : "left_icon_zero"}
                onClick={this.setLeftpan2}
              >
                {this.context.showLeftPane ? null : (
                  <i className="fa fa-arrow-right"></i>
                )}
              </div>
              <div className="row"   style={{ "--bs-gutter-x": "0px" }} >
                <div
                  id="refresh-disabled"
                  className={this.getClassName()}
                  style={{
                    // paddingRight: "0px",
                    // height: this.state.height,
                    // maxHeight: this.state.height,
                    borderTop: "0px",
                    // overflow: "auto",
                  }}
                >
                  <div className="">
                    {
                      <LeftNav
                        height={this.state.height}
                        sendLandingPref={(lanpref) =>
                          this.sendLandingPref(lanpref)
                        }
                        headHeight={this.state.headHeight}
                        showOtherCompo={(nm) => this.props.showOtherCompo(nm)}
                        showClientReg={(nm) => this.props.showClientReg(nm)}
                        showMultiTable={(nm) => this.props.showMultiTable(nm)}
                        showClientInfo={(nm) => this.props.showClientInfo(nm)}
                        handleLeftPane={(val) => this.setLeftPan(val)}
                        showLeftPane={this.context.showLeftPane}
                        hideLeftPanForMob={this.props.hideLeftPanForMob}
                      ></LeftNav>
                      /*  <h1>Danish Ansari</h1> */
                    }
                  </div>
                </div>
                {this.context.showLeftPane && this.context.isMobile && (
                  <div className="g-overlay" onClick={this.handleClick}></div>
                )}

                <div
                  className={this.getClassName2()}
                  style={{
                    height: this.state.height,
                    maxHeight: this.state.height,
                    // overflowY: "scroll",
                  }}
                >
                  <Outlet />
                </div>
              </div>

              <Modal
                isOpen={this.context.isOpen}
                onRequestClose={this.context.closeModal}
                onAfterOpen={this.handleClick2}
                style={{
                  content: {
                    width: "320px",
                    height: "350px",
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "11px",
                    background: "#010154",
                    // zIndex: "1000",
                  },
                }}
                ariaHideApp={false}
                contentLabel="Example Modal"
              >
                {/* <h2 style={{ color: "white", textAlign: "center" }}>UserProfile</h2> */}
                <div
                  style={{
                    fontSize: "75px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <i
                    className="fa fa-user-circle-o"
                    aria-hidden="true"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Profile"
                  ></i>
                  <p
                    className="text-capitalize"
                    style={{
                      fontSize: "20px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {this.context.userinfo.name}
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-danger form-control"
                    onClick={this.logout}
                  >
                    Logout
                  </button>
                  <button
                    className="btn btn-primary form-control"
                    onClick={this.context.closeModal}
                  >
                    Close
                  </button>
                </div>
              </Modal>

              <Mod
                show={this.context.showNoti}
                onHide={this.context.handleNotificationCompo}
                // size="xl"
                // aria-labelledby="example-modal-sizes-title-sm"
                // className="modal-xl-custom modal-md-custom"
                dialogClassName="modal-xl-custom modal-md-custom"
              >
                <Mod.Header closeButton>
                  <Mod.Title>Notification</Mod.Title>
                </Mod.Header>
                <Mod.Body>
                  <UserNotificationCompo
                    showNotificationCompo={(cnt, val) =>
                      this.context.showNotificationCompo(cnt, val)
                    }
                    /*   showFormCompo={(nm, rid, ty) => this.showFormCompo(nm, rid, ty)} */
                    loca={this.context.loca}
                    msg={this.context.msg}
                    cnt={this.context.cnt}
                    context={this.context}
                  ></UserNotificationCompo>
                </Mod.Body>
                <Mod.Footer>
                  <Button
                    variant="secondary"
                    onClick={this.context.handleNotificationCompo}
                  >
                    Close
                  </Button>
                </Mod.Footer>
              </Mod>

              <Mod
                show={this.context.adminPanel}
                onHide={this.context.closeList}
                size="xl"
              >
                <Mod.Header closeButton>
                  <Mod.Title>Impersonation</Mod.Title>
                </Mod.Header>
                <Mod.Body>
                  <ImpersonationCompo
                    adminPanel={this.context.adminPanel}
                    loca={this.context.loca}
                    setImp={(rel) => this.setImp(rel)}
                    user_rcd={this.context.setRecord}
                    isMobile={this.context.isMobile}
                  ></ImpersonationCompo>
                </Mod.Body>
                <Mod.Footer>
                  <Button variant="secondary" onClick={this.context.closeList}>
                    Close
                  </Button>
                </Mod.Footer>
              </Mod>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default WithNavigate(PrivateCompo);
