import React, { Component } from "react";
import axios from "axios";
import GlobalContext from "./component/GlobalContext ";
import { AppConfig } from "./Utility/AppConfig";

export class MayaAjax extends Component {
    static contextType = GlobalContext;
  state = {
    scriptIncludeName:"",
    req:[],
    res:[],
    loca: AppConfig.loca,
  };

  rqq=[]
  
  constructor(scriptIncludeName) {
    super(scriptIncludeName);
    this.state.scriptIncludeName = scriptIncludeName;
  }

  
addParam =(key ,value)=>{
   // let rq=this.state.req;
    this.rqq.push({key:key,value:value})
 //  this.setState({req:[...rq]})
  
}

callAsync =(callBack)=>{
    let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
    var token = localStorage.getItem("token");
 
    
   if(token){
    axios
    .post(
      this.state.loca +
      "/loom/maya/ajax" ,
      obj,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      }
    ).then((res)=>{
  
     let response=res.data;
     
      callBack(response)
    }
      
    )
    .catch((err)=>{
      
    })
   }
}

// sync way
callApi= async ()=>{
  let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
  var token = localStorage.getItem("token");

  if(token){
    try{

      const res= await axios.post( this.state.loca +
        "/loom/maya/ajax" ,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
      
        return res.data;
    }catch(err){
        return "";
    }
  }
}



apiRequest =(callBack)=>{
  let obj={request:this.rqq ,scriptIncludeName:this.state.scriptIncludeName}
  var token = localStorage.getItem("token");

  
 if(token){
  axios
  .post(
    this.state.loca +
    "/loom/maya/ajax" ,
    obj,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    }
  ).then((res)=>{

   let response=res.data;

 
 
  
   if(callBack)
    callBack(response)
  }
    
  )
  .catch((err)=>{
   
  })
 }

}

redirectOnUI=(callBack)=>{
 // callBack
   callBack();
}




  render() {
    return <div>ApUser</div>;
  }
}

export default MayaAjax;
