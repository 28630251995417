import React, { Component } from "react";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import { toast } from "react-toastify";
import "../css/formView.css";
import "../css/formcompo.css";
import { auto } from "@popperjs/core";
import WithNavigate from "./withNavigate";
import { AppConfig } from "../Utility/AppConfig.js";

class MultipleApplicationRequest extends Component {
  state = {
    loading: false,
    col_mn: [],
    columnarray2: [""],
    page_error: false,
    page_message: false,
    message: "",
    loca: AppConfig.loca,
    isColArr2Selected: false,
    flag: false,
    ind: 0,
    isMobile: this.props.isMobile,
    mncolor: false,
    toastVisible: false,
    searchVal: "",
    jwtLoca: AppConfig.jwtLoca,
  };

  constructor(props) {
    super(props);
    this.submitColumnbtn = this.submitColumnbtn.bind(this);
    this.shiftLeft = this.shiftLeft.bind(this);
    this.selected = this.selected.bind(this);
    this.setColumnOrder = this.setColumnOrder.bind(this);
  }

  componentDidMount() {
    this.updateSearchParams();
    this.formChangeTable();
  }

  componentDidUpdate(prev) {
    if (this.props.location.search != prev.location.search) {
      this.updateSearchParams(prev);
      this.formChangeTable();
    }
  }

  updateSearchParams = (prev) => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");
  };

  async formChangeTable(val, dontFalse) {
    var token = localStorage.getItem("token");
    var userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    this.setState({
      isColArrSelected: false,
      isColArr2Selected: false,
    });

    if (val !== "" && val !== "none") {
      this.setState({
        col_mn: [],
      });

      if (!dontFalse) {
        this.setState({
          col_mn: [],
          isColArrSelected: false,
          isColArr2Selected: false,
          page_error: false,
          error: "",
          page_message: false,
          message: "",
          flag: true,
        });
      }
      let url = AppConfig.jwtLoca + "/getAllApplicationList";
      axios
        .post(
          url,
          { userName: userDetails.name },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const coldata = resp.data;
            if (coldata !== "") {
              if ("Error" in coldata) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: coldata.Error,
                });
              } else {
                coldata.map((app) => (app.check = false));
                this.setState({
                  col_mn: coldata,
                });
              }
            }
          },
          (error) => {
            this.props.showErrorCompo();
          }
        );
    }
  }

  submitColumnbtn() {
    var colm = this.state.col_mn;
    //   var colm = this.changeIntoOriginalColmn(this.state.tabname);
    //   let n = {  name: clm[i].name, co: n_co, appCode:clm[i].appCode ,securityType:clm[i].securityType };

    // var colm = this.state.col_mn;
    var colm2 = this.state.columnarray2;
    var sub = [];
    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].name === colm[j].name) {
          sub.push(colm2[i]);
          break;
        }
      }
    }

    let json = {};
    var token = localStorage.getItem("token");
    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    json.userName = userDetails.username;
    json.secondaryApp = sub;
    //   axios
    //     .post(this.state.loca + "/loom/set/view/column/", json, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: "Bearer " + token,
    //       },
    //     })
    //     .then((resp) => {
    //       let rsp = resp.data;
    //       if (rsp !== "") {
    //         if ("Error" in rsp) {
    //           this.setState({
    //             loading: false,
    //             page_error: true,
    //             error: rsp.Error,
    //           });
    //         } else {
    //           this.setState({
    //             loading: false,
    //             page_message: true,
    //             message: rsp.Message,
    //           })
    //           setTimeout(() => {
    //             this.setState({ page_message: false });
    //           }, 2000);
    //         }
    //       }
    //     },
    //       (error) => {
    //         this.props.showErrorCompo();
    //       });
  }

  selected(index, clm) {
    this.setState({
      isColArrSelected: true,
      isColArr2Selected: false,
    });

    var col_mn = this.state.col_mn;
    var columnarray2 = this.state.columnarray2;
    if (clm === "clm1") {
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index) {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("colm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      this.setState({
        isColArrSelected: false,
        isColArr2Selected: true,
        ind: index,
      });
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < col_mn.length; i++) {
        document
          .getElementById("colm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  }

  setColumnOrder(pm) {
    var clm = [];
    var col_odr = this.state.ind;
    var col_odr2 = 0;
    var columnarray2 = this.state.columnarray2;

    if (pm) {
      if (col_odr < columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        this.setState({
          columnarray2: clm,
          ind: col_odr2,
          page_message: false,
        });
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        for (let i = 0; i < columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("colm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr + 1).toString();
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("colm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = columnarray2[i];
            cjj.co = (col_odr2 + 1).toString();
            clm.push(cjj);
          }
        }
        clm.sort((a, b) =>
          parseInt(a.co) > parseInt(b.co)
            ? 1
            : parseInt(a.co) < parseInt(b.co)
            ? -1
            : 0
        );
        this.setState({
          columnarray2: clm,
          ind: col_odr2,
          page_message: false,
        });
      }
    }
  }

  shiftRight() {
    let clm = this.state.col_mn;
    let col_mn = this.state.col_mn;
    let clm2 = this.state.columnarray2;
    let lab = document.getElementsByClassName("selected-row").item(0);
    if (lab !== null) {
      lab = lab.innerText;
      for (let i = 0; i < col_mn.length; i++) {
        if (clm[i].name === lab) {
          if (clm[i].type !== "ui") {
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;

          let n = {
            name: clm[i].name,
            co: n_co,
            appCode: clm[i].appCode,
            securityType: clm[i].securityType,
          };
          clm2.push(n);
        }
      }
      this.setState({
        isColArrSelected: false,
        col_mn: clm,
        columnarray2: clm2,
        page_message: false,
      });
    } else {
      this.setState({
        isColArrSelected: true,
        isColArr2Selected: true,
        page_message: false,
      });
    }
  }
  ss;

  showToast = (message) => {
    if (!this.state.toastVisible) {
      this.setState({ toastVisible: true });
      toast(message, {
        position: "bottom-center",
        theme: "colored",
        type: "success",
        onClose: () => {
          this.setState({ toastVisible: false }); // Reset flag when toast disappears
        },
        autoClose: 900,
      });
    }
  };
  shiftLeft() {
    let clm = this.state.col_mn;
    let clm2 = this.state.columnarray2;
    let obj = {};
    let name = "";
    let lab = document.getElementsByClassName("selected-row").item(0);
    if (lab !== null) {
      lab = lab.innerText;
      for (let i = 0; i < clm2.length; i++) {
        if (i === this.state.ind) {
          obj = clm2[i];
          name = clm2[i].name;
          clm2.splice(i, 1);
        }
      }
      for (let i = 0; i < this.state.col_mn.length; i++) {
        if (clm[i].name === name) {
          clm[i].check = false;
        }
      }

      this.setState({
        isColArrSelected: false,
        isColArr2Selected: true,
        col_mn: clm,
        columnarray2: clm2,
      });
    } else {
      this.setState({ isColArrSelected: true, isColArr2Selected: true });
    }
  }

  render() {
    return (
      <div
        className="Card"
        style={
          this.state.isMobile
            ? { height: "93vh", overflow: auto, flexGrow: 1 }
            : { height: "95vh", flexGrow: 1 }
        }
      >
        <div className="formViewBack">
          {this.state.loading === true ? (
            <WorkInProgress></WorkInProgress>
          ) : (
            <div>
              {this.state.isColArrSelected === true &&
                this.state.isColArr2Selected === true && (
                  <div
                    className="alertgp alert-warning"
                    style={{
                      fontSize: "initial",
                      color: "black",
                    }}
                  >
                    {"Please Select Column"}
                  </div>
                )}

              {this.state.page_error === true && (
                <div
                  className="alertgp alert-danger"
                  style={{
                    fontSize: "initial",
                    color: "black",
                  }}
                >
                  {this.state.error}
                </div>
              )}
              {this.state.page_message === true && (
                <div
                  className="alertgp alert-success"
                  style={{
                    fontSize: "initial",
                    color: "black",
                  }}
                >
                  {this.state.message}
                </div>
              )}
            </div>
          )}
          {this.state.flag && (
            <div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-md-2"></div>
                <div className="col-md-8 my-4">
                  <div className="input-group">
                    <div className="form-outline" /* data-mdb-input-init */>
                      <input
                        type="search"
                        id="form1"
                        className="form-control"
                        value={this.state.searchVal}
                        onChange={(e) => {
                          this.setState({ searchVal: e.target.value });
                          let ot = this.state.col_mn.map((obj) => {
                            if (obj.name.includes(this.state.searchVal)) {
                              obj.inSearch = true;
                            } else {
                              obj.inSearch = false;
                            }
                            return obj;
                          });
                          this.setState({ col_mn: ot });
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary mt-0"
                      data-mdb-ripple-init
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                  <div className="row">
                    <div id="colm1" className="col-md-4  minHeight">
                      {this.state.col_mn.length > 0 &&
                        this.state.searchVal &&
                        this.state.col_mn.map((obj, obj_i) => (
                          <div key={obj_i}>
                            {!obj.check && obj.inSearch && (
                              <div className="row fr">
                                <div
                                  className="col-md heading_pref"
                                  onClick={() => {
                                    this.selected(obj_i, "clm1");
                                  }}
                                >
                                  <span className="blackLab">
                                    <b>{obj.name}</b>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      {this.state.col_mn.length > 0 &&
                        !this.state.searchVal &&
                        this.state.col_mn.map((obj, obj_i) => (
                          <div key={obj_i}>
                            {!obj.check && (
                              <div className="row fr">
                                <div
                                  className="col-md heading_pref"
                                  onClick={() => {
                                    this.selected(obj_i, "clm1");
                                  }}
                                >
                                  <span className="blackLab">
                                    <b>{obj.name}</b>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="up-down-dis col-md-2">
                      <div className="cen">
                        <button
                          className="view_btn"
                          disabled={this.state.isColArr2Selected}
                          onClick={() => {
                            this.shiftRight();
                          }}
                        >
                          {">"}
                        </button>
                      </div>
                      <div className="cen">
                        <button
                          className="view_btn"
                          disabled={this.state.isColArrSelected}
                          onClick={() => {
                            this.shiftLeft();
                          }}
                        >
                          {"<"}
                        </button>
                      </div>
                    </div>
                    <div id="colm2" className="col-md-4  minHeight">
                      {this.state.columnarray2.map((obj, index) => (
                        <p
                          style={{ margin: 0 }}
                          key={index}
                          className="columnarray2 heading_pref"
                          onClick={() => {
                            this.selected(index, "clm2");
                          }}
                          value={obj.name}
                        >
                          <b>{obj.name}</b>
                        </p>
                      ))}
                    </div>
                    <div className="col-md-2 up-down-dis">
                      <div className="cen">
                        <button
                          className="up-down-view"
                          aria-hidden="true"
                          onClick={(e) => this.setColumnOrder(false)}
                        >
                          {">"}
                        </button>
                      </div>
                      <div className="cen">
                        <button
                          className="up-down-view"
                          aria-hidden="true"
                          onClick={(e) => this.setColumnOrder(true)}
                        >
                          {"<"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="row my-4">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-2 cen">
                      <button
                        style={{
                          fontSize: "15px",
                          borderRadius: "3px",
                        }}
                        type="button"
                        className=" btnnn btn btn-primary"
                        onClick={() => this.submitColumnbtn()}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default WithNavigate(MultipleApplicationRequest);
