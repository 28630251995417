import React, { Component } from "react";
import axios from "axios";
import "../css/sortcompo.css";
import WorkInProgress from "./work_in_progress";
import WithNavigate from "./withNavigate";
import GlobalContext from "./GlobalContext ";

class SortComponent extends Component {
  static contextType = GlobalContext;

  state = {
    list: [],
    listt: [],
    name: "",
    tableName: "",
    showList: true,
    loading: false,
    sortedName: "", //tab
    taskName: "", // name
    url: "",
    page_error: false,
    error: "",
    loca: this.props.loca,
    isMobile: this.props.isMobile,
  };

  constructor(props) {
    super(props);
    this.callform = this.callform.bind(this);
    this.callList = this.callList.bind(this);
    this.backButton = this.backButton.bind(this);
    this.listOfTypes = [
      "filter",
      "filter_ref",
      "json",
      "group_key_value",
      "html",
      "multi_select"
    ];
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .get(this.state.loca + "/loom/sorted/" + this.state.sortedName, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const list = resp.data;
          console.log("sorted", list);
          if (list !== "") {
            if ("Error" in list) {
              this.setState({
                loading: false,
                page_error: true,
                error: list.Error,
              });
            } else {
              var columnarry1 = [];

              for (var ii = 0; ii < list.sortedrecord.value.length; ii++) {
                if (list.sortedrecord.value[ii].name !== this.state.taskName)
                  continue;
                var hd = [];
                var va_l = [];
                var tab = list.sortedrecord.value[ii].loomtable.value;
                for (
                  var jj = 0;
                  jj < list.sortedrecord.value[ii].records.length;
                  jj++
                ) {
                  var in_vl = [];
                  var record_id;
                  for (
                    var kk = 0;
                    kk < list.sortedrecord.value[ii].records[jj].record.length;
                    kk++
                  ) {
                    if (jj === 0) {
                      if (kk === 0) {
                      } else {
                        hd.push({
                          label:
                            list.sortedrecord.value[ii].records[jj].record[kk]
                              .label,
                          name: list.sortedrecord.value[ii].records[jj].record[
                            kk
                          ].name,
                        });
                      }
                    }
                    if (kk === 0) {
                      record_id =
                        list.sortedrecord.value[ii].records[jj].record[kk]
                          .value;
                    } else if (kk === 1) {
                      if (
                        list.sortedrecord.value[ii].records[jj].record[kk]
                          .type === "reference"
                      ) {
                        in_vl.push({
                          value:
                            list.sortedrecord.value[ii].records[jj].record[kk]
                              .value.value,
                          type: list.sortedrecord.value[ii].records[jj].record[
                            kk
                          ].type,
                          firstrecord: true,
                          table: tab,
                          r_id: record_id,
                        });
                      } else {
                        in_vl.push({
                          value:
                            list.sortedrecord.value[ii].records[jj].record[kk]
                              .value,
                          type: list.sortedrecord.value[ii].records[jj].record[
                            kk
                          ].type,
                          firstrecord: true,
                          table: tab,
                          r_id: record_id,
                        });
                      }
                    } else {
                      if (
                        list.sortedrecord.value[ii].records[jj].record[kk]
                          .type === "reference"
                      ) {
                        in_vl.push({
                          value:
                            list.sortedrecord.value[ii].records[jj].record[kk]
                              .value.value,
                          type: list.sortedrecord.value[ii].records[jj].record[
                            kk
                          ].type,
                          firstrecord: false,
                          table: tab,
                          r_id: record_id,
                        });
                      } else {
                        in_vl.push({
                          value:
                            list.sortedrecord.value[ii].records[jj].record[kk]
                              .value,
                          type: list.sortedrecord.value[ii].records[jj].record[
                            kk
                          ].type,
                          firstrecord: false,
                          table: tab,
                          r_id: record_id,
                        });
                      }
                    }
                  }
                  va_l.push({ colr: in_vl });
                }
                var filter = "";
                if (
                  list.sortedrecord.value[ii].list_filter !== "null" &&
                  list.sortedrecord.value[ii].list_filter !== ""
                ) {
                  filter = list.sortedrecord.value[ii].list_filter;
                }
                var timeline = "";
                if (
                  list.sortedrecord.value[ii].timeLine !== "null" &&
                  list.sortedrecord.value[ii].timeLine !== ""
                ) {
                  timeline = list.sortedrecord.value[ii].timeLine;
                }
                columnarry1.push({
                  heading: hd,
                  rcd: va_l,
                  name: list.sortedrecord.value[ii].name,
                  blank: list.sortedrecord.value[ii].blank,
                  tableName: list.sortedrecord.value[ii].loomtable.value,
                  filter: filter,
                  timeline: timeline,
                });
              }

              console.log("columnarry1" ,columnarry1);
              
              this.setState({
                loading: false,
                listt: columnarry1,
              });
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");
    let name = searchParams.get("name");
    let url = searchParams.get("url");
    if (tableName !== state.sortedName) {
      return {
        sortedName: tableName,
        taskName: name,
        url: url,
        isMobile: props.isMobile,
      };
    } else if (tableName === state.sortedName) {
      if (name !== state.name) {
        return {
          sortedName: tableName,
          taskName: name,
          url: url,
          isMobile: props.isMobile,
        };
      }
    } else if (url === state.url) {
      if (name === state.name && url !== state.url) {
        return {
          sortedName: tableName,
          taskName: name,
          url: url,
          isMobile: props.isMobile,
        };
      }
    }
    return null;
  }

  dismiss() {
    this.props.unmountMe();
  }

  callform(nam, tab, r_id) {
    if (nam === "first") {
      // this.props.showFormCompo(tab, r_id, "record");
      const { navigateWithSearchParams } = this.props;
      navigateWithSearchParams(
        "/private/form",
        {
          rty: "record",
          tableName: tab,
          rid: r_id,
        },
        {}
      );
    } else if (nam === "second") {
    }
  }

  callList(name, fil, tm) {
    // this.props.showListCompo(name, fil, tm);
    const { navigateWithSearchParams } = this.props;

    navigateWithSearchParams(
      "/private/list",
      {
        rty: "recordList",
        tableName: name,
      },
      { filter: fil, timeLine: tm }
    );
  }

  getMultiSelectValues=(obj)=>{
    console.log(obj);
    let rcd=obj.record;
    let res="";
    if(rcd?.length!=0){
      for(let k=0; k<10 && k<rcd?.length;k++){
        if(k>0)
          res+=",";
         res=res+ rcd[k]?.value
      }
    }
    console.log(res);
    return res;
  }

  checkIfTypeNotExists = (type) => {
    for (let i = 0; i < this.listOfTypes.length; i++) {
      if (this.listOfTypes[i] === type) {
        return false;
      }
    }
    return true;
  };

  backButton() {
    // const { navigateWithSearchParams } = this.props;
    // navigateWithSearchParams('/private/dashboard')
    this.props.navigate("/private/dashboard");
  }

  render() {
    return (
      <div className=" pagesetup">
        {this.state.loading === true ? (
          <div className="g-compo-loader">
            <WorkInProgress></WorkInProgress>
          </div>
        ) : (
          <div>
            <span>
              <i
                className=" backic fa fa-arrow-left mrg-left"
                aria-hidden="true"
                onClick={this.backButton}
              ></i>
            </span>
            {this.state.listt.length === 0 && <div>No Record Found</div>}
            {this.state.listt.map((lstobj, ls_i) => (
              <div className="ovly up_tab" key={ls_i}>
                <div className="cen obj-name">{lstobj.name}</div>
                <div className="heading_top table_set over">
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead style={{whiteSpace:"nowrap"}}>
                      <tr className="obj_name">
                        {lstobj.heading.map((obj, oj_i) => (
                          <th className="" key={oj_i}>
                            {obj.label}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    {lstobj.blank === "true" && (
                      <tbody>
                        <tr className="obj_value">
                          <td colSpan={lstobj.heading.length}>No Record</td>
                        </tr>
                      </tbody>
                    )}
                    {lstobj.blank === "false" && (
                      <tbody>
                        {lstobj.rcd.map((objj, bj_i) => (
                          <tr className="obj_value" key={bj_i}>
                            {objj.colr.map((objr, br_i) => (
                              <td key={br_i}>
                              <span
                              className={
                                objr.firstrecord === true
                                  ? "val_pad val_under vlpointer"
                                  : "val_pad"
                              }
                              onClick={
                                objr.firstrecord === true
                                  ? (e) =>
                                      this.callform(
                                        "first",
                                        objr.table,
                                        objr.r_id
                                      )
                                  : (e) => this.callform("second", "", "")
                              }
                            >
                              {objr.type === "filter" ||
                              objr.type === "filter_ref" ||
                              objr.name === "json" && (
                                JSON.stringify( objr.value)?.length > 50
                                ?  JSON.stringify( objr.value).slice(0, 50) + " ..."
                                :  JSON.stringify( objr.value)
                              
                              )} 
                              
                          { objr.type === "group_key_value" && (
                                JSON.stringify(
                                  objr.value.properties?.length > 50
                                    ? objr.value.properties.slice(0, 50) +
                                        " ..."
                                    : objr.value.properties
                                )
                              ) }
                              {objr.type === "html" && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      objr.value?.length > 50
                                        ? objr.value.slice(0, 50) + " ..."
                                        : objr.value,
                                  }}
                                ></span>)}
                            {objr.type === "multi_select" && (

                              this.getMultiSelectValues(objr.value).length > 50 ? (
                                this.getMultiSelectValues(objr.value).slice(0, 50) + " ..."
                              ) : (
                                this.getMultiSelectValues(objr.value))
                             )}
                              
                            {this.checkIfTypeNotExists(objr.type)&&(
                               objr.value?.length > 50 ? (
                                objr.value.slice(0, 50) + " ..."
                              ) : (
                                objr.value
                              )
                            )}
                              </span>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                  {/* && lstobj.rcd.length > 15 */}
                  {lstobj.blank === "false" && (
                    <button
                      type="button"
                      className=" loadbtn btn btn-warning"
                      onClick={(e) =>
                        this.callList(
                          lstobj.tableName,
                          lstobj.filter,
                          lstobj.timeline
                        )
                      }
                    >
                      Go To List
                      {/* Load more */}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default WithNavigate(SortComponent);
