import React, { Component } from "react";
import axios from "axios";
import "../css/userPref.css";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { Button } from "react-bootstrap";

class PreferenceComponent extends Component {
  state = {
    tablename: "",
    columnarray: [{}],
    columnarray2: [],
    prefarray: [],
    json: {},
    pref: "",
    loca: this.props.loca,
    col_val: [{}],
    isColArrSelected: false,
    isColArr2Selected: false,
    ind: 0,
    isMobile: this.props.isMobile,
  };

  constructor(props) {
    super(props);
    this.state.pref = props.pref;
    this.state.columnarray = props.columnarray;
    this.state.tablename = props.tablename;
    this.setColumnbtn = this.setColumnbtn.bind(this);
    this.submitColumnbtn = this.submitColumnbtn.bind(this);
    this.setColumn = this.setColumn.bind(this);
    this.changeColumn = this.changeColumn.bind(this);
    this.setColumnOrder = this.setColumnOrder.bind(this);
    this.shiftLeft = this.shiftLeft.bind(this);
    this.shiftRight = this.shiftRight.bind(this);
    this.selected = this.selected.bind(this);
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    axios
      .get(
        this.state.loca +
          "/loom/get/pref/" +
          this.state.pref +
          "/" +
          this.state.tablename,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          var sysrecord = resp.data;
          console.log("sysrecord: ",sysrecord);
          if (sysrecord !== "") {
            if ("Error" in sysrecord) {
            } else {
              if (this.state.pref === "user") {
                this.setState({
                  prefarray: sysrecord.userPreference[2].column,
                  json: sysrecord,
                });
              } else if (this.state.pref === "owner") {
                this.setState({
                  prefarray: sysrecord.ownerPreference[2].column,
                  json: sysrecord,
                });
              }
              console.log("sysrecord",sysrecord);
              
              this.setColumn();
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  componentDidUpdate(props) {}

  // setColumn() {
  //     var clm = this.state.columnarray;
  //     var clm2 = this.state.columnarray2;
  //     var prf = this.state.prefarray;
  //     for (var ii = 0; ii < clm.length; ii++) {
  //         clm[ii].check = false;
  //     }
  //     for (var i = 0; i < clm.length; i++) {
  //         for (var p = 0; p < prf.length; p++) {
  //             if (clm[i].name === prf[p].name) {
  //                 clm[i].check = true;
  //                 var jj = {
  //                     label: clm[i].label,
  //                     co: prf[p].co,
  //                 };
  //                 clm2.push(jj);
  //             }
  //         }
  //     }
  //     // let c = [];
  //     // if (clm[clm.length-1].label!=="split-end") {
  //     //     clm.push({label:"split-start",type:"ui",check:"false"})
  //     //     clm.push({label:"split",type:"ui",check:"false"})
  //     //     clm.push({label:"split-end",type:"ui",check:"false"})
  //     // }

  //     let clm22 = clm2.sort((a, b) => (a.co > b.co ? 1 : -1));
  //     this.setState({ columnarray: clm, columnarray2: clm2 });
  // };

  setColumn() {
    var clm = this.state.columnarray;
    var clm2 = this.state.columnarray2;
    var prf = this.state.prefarray;
    console.log("clm" ,JSON.parse(JSON.stringify(clm)));
    
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    // var pref = prf[0];
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].name === prf[p].name) {
          clm[i].check = true;
          let od="";
          if(typeof prf[p].co ==='string'){
            od = Number(prf[p].co)
          }else{
             od=prf[p].co;
          }
          var jj = {
            label: clm[i].label,
            name: clm[i].name,
            co: od,
          };
          clm2.push(jj);
        }
      }
    }
    console.log("clm" ,clm);
    console.log("clm2" ,JSON.parse(JSON.stringify(clm2)));
    
    clm2.sort((a, b) => (a.co > b.co ? 1 : -1));

    console.log("clm2 after sort" ,clm2);
    this.setState({ columnarray: clm, columnarray2: clm2 });
  }

  changeColumn(e, index) {
    var col = this.state.columnarray;
    col[index].check = e;
    this.setState({ columnarray: col });
  }

  setColumnbtn() {
    var colm = this.state.columnarray;
    var colm2 = this.state.columnarray2;
    for (var i = 0; i < colm.length; i++) {
      var dd = false;
      var ind = 0;
      var ee = false;
      for (var c = 0; c < colm2.length; c++) {
        if (colm[i].check === true) {
          if (colm[i].label === colm2[c].label) {
            dd = true;
          }
        } else if (colm[i].check === false) {
          if (colm[i].label === colm2[c].label) {
            ee = true;
            ind = c;
          }
        }
      }
      if (dd === false && colm[i].check === true) {
        var ln = colm2.length;
        ln = ln + 1;
        colm2.push({ label: colm[i].label, co:ln  });
      }
      if (ee === true && colm[i].check === false) {
        colm2.splice(ind, 1);
      }
    }
    this.setState({ columnarray2: colm2 });
  }

  submitColumnbtn() {
    var token = localStorage.getItem("token");
    var colm = this.state.columnarray;
    var colm2 = this.state.columnarray2;
    var json = this.state.json;
    var sub = [];
    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].label === colm[j].label) {
          let od="";
          if(typeof colm2[i].co ==='string'){
           od=  Number(colm2[i].co)
          }else{
             od=colm2[i].co;
          }
          sub.push({ name: colm[j].name, co: od });
        }
      }
    }

    if (this.state.pref === "user") {
      json.userPreference[2].column = sub;
      json.userPreference[1].table.value = this.state.tablename;
      axios
        .post(this.state.loca + "/loom/set/user/preference/", this.state.json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;
          this.props.handleClose();
        });
    } else if (this.state.pref === "owner") {
      json.ownerPreference[2].column = sub;
      json.ownerPreference[1].table.value = this.state.tablename;
      axios
        .post(
          this.state.loca + "/loom/set/owner/preference/",
          this.state.json,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          }
        )
        .then((resp) => {
          var sysRecord = resp.data;
          toast(sysRecord.Message, {
            position: "top-center",
            theme: "colored",
            type: "success",
          });
          // this.props.handleClose();
        });
    }
  }

  selected(index, clm) {
    if (clm === "clm1") {
      this.setState({ isColArrSelected: true, isColArr2Selected: false });
      for (let i = 0; i < this.state.columnarray.length; i++) {
        if (i === index) {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }

      for (let i = 0; i < this.state.columnarray2.length; i++) {
        document
          .getElementById("clm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {

      console.log("columnarray2" ,this.state.columnarray2);
      console.log("index" ,index);
      
      if (this.state.columnarray2.length == 1) {
        this.setState({ isColArrSelected: true });
      } else {
        this.setState({ isColArrSelected: false });
      }
      this.setState({ isColArr2Selected: true, ind: index });

      for (let i = 0; i < this.state.columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < this.state.columnarray.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  }

  setColumnOrder(pm) {
    let clm = [];
    let col_odr = this.state.ind;
    let col_odr2 = 0;
    console.log(this.state.columnarray2);
    console.log(pm);
    console.log("col_odr",col_odr);
    
    

    console.log("indddddddddd", this.state.columnarray2);

    if (pm) {
      if (col_odr < this.state.columnarray2.length - 1) {
        col_odr2 = col_odr + 1;
        console.log("col_odr2" ,col_odr2);
        
        for (let i = 0; i < this.state.columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(this.state.columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("clm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = this.state.columnarray2[i];
            cjj.co = (col_odr + 1);
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("clm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = this.state.columnarray2[i];
            cjj.co = (col_odr2 + 1);
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (Number(a.co) > Number(b.co) ? 1 : -1));
        console.log("clmmmm", clm);
        this.setState({ columnarray2: clm, ind: col_odr2 });
      }
    } else {
      if (col_odr > 0) {
        col_odr2 = col_odr - 1;
        for (let i = 0; i < this.state.columnarray2.length; i++) {
          if (i !== col_odr2 && i !== col_odr) {
            clm.push(this.state.columnarray2[i]);
          } else if (i === col_odr2) {
            document
              .getElementById("clm2")
              .children.item(col_odr2)
              .classList.add("selected-row");
            let cjj = this.state.columnarray2[i];
            cjj.co = (col_odr + 1);
            clm.push(cjj);
          } else if (i === col_odr) {
            document
              .getElementById("clm2")
              .children.item(col_odr)
              .classList.remove("selected-row");
            let cjj = this.state.columnarray2[i];
            cjj.co = (col_odr2 + 1);
            clm.push(cjj);
          }
        }
        clm.sort((a, b) => (Number(a.co) > Number(b.co) ? 1 : -1));
        console.log("clmmmm1", clm);
        this.setState({ columnarray2: clm, ind: col_odr2 });
      }
    }
  }

  shiftRight() {
    let clm = this.state.columnarray;
    let clm2 = this.state.columnarray2;

    let goInside = false;
    let index = -1;
    if (document.getElementsByClassName("selected-row").item(0)) {
      // if (lab !== null) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < this.state.columnarray.length; i++) {
        if (clm[i].label === lab) {
          //  if (i === this.state.ind) {

          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
          if (clm[i].type !== "ui") {
            clm[i].check = true;

            goInside = true;
            index = i;
          }
          let n_co = clm2.length + 1;
          let n = { label: lab, name: clm[i].name, co: n_co };
          clm2.push(n);
          //   }
        }
      }

      this.setState({
        /* isColArrSelected: true,  */ columnarray: clm,
        columnarray2: clm2,
      });
      // } else {
      this.setState({ isColArrSelected: true, isColArr2Selected: true }); //danish
      // }
    }
  }

  shiftLeft() {
    let clm = this.state.columnarray;
    let clm2 = this.state.columnarray2;
    let name = "";
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      let removedIndex = -1;
      for (let i = 0; i < this.state.columnarray2.length; i++) {
        // if (clm2[i].label === lab) {
        //     clm2.splice(i, 1);
        // }
        if (i === this.state.ind) {
          name = clm2[i].name;
          removedIndex = i;
        }
        if (i > this.state.ind) {
          let newCO = Number(clm2[i].co) - 1;
          clm2[i].co = newCO;
        }
      }

      if (removedIndex != -1) {
        clm2.splice(removedIndex, 1);
        document
          .getElementsByClassName("selected-row")
          .item(0)
          .classList.remove("selected-row");
      }
      for (let i = 0; i < this.state.columnarray.length; i++) {
        if (clm[i].name === name) {
          clm[i].check = false;
        }
      }
      this.setState({
        /* isColArr2Selected: false, */ columnarray: clm,
        columnarray2: clm2,
      });
      this.setState({ isColArrSelected: true, isColArr2Selected: true }); //danish
    }
  }

  render() {
    return (
      <div>
        <div style={{ flexGrow: 1 }}>
          <div className="row fr justify-content-center">
            <div
              id="clm1"
              className="col-md-4  col-sm-3 box-pref"
              style={{ padding: "0px" }}
            >
              {this.state.columnarray.length > 0 &&
                this.state.columnarray.map((obj, obj_i) => (
                  <div key={obj_i}>
                    {!obj.check && (
                      <div
                        className="row fr"
                        style={{
                          borderBottom:
                            obj_i !== this.state.columnarray.length - 1 &&
                            "1px solid #e3e3e3",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                          className="columnarray2"
                          onClick={() => {
                            this.selected(obj_i, "clm1");
                          }}
                        >
                          {obj.label}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            {!this.state.isMobile ? (
              <div className="up-down-view-pref2">
                <div>
                  <button
                    disabled={this.state.isColArr2Selected}
                    className="ryt-left-btn"
                    // aria-hidden="true"
                    onClick={() => {
                      this.shiftRight();
                    }}
                  >
                    {">"}
                  </button>
                </div>
                <div>
                  <button
                    disabled={this.state.isColArrSelected}
                    className="ryt-left-btn"
                    // aria-hidden="true"
                    onClick={() => {
                      this.shiftLeft();
                    }}
                  >
                    {"<"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="up_down d-flex justify-content-center pt-1 pb-1">
                <button
                  disabled={this.state.isColArrSelected}
                  className="up-down-view-pref3"
                  aria-hidden="true"
                  onClick={(e) => this.shiftLeft()}
                >
                  {">"}
                </button>
                <button
                  disabled={this.state.isColArr2Selected}
                  className="up-down-view-pref3"
                  aria-hidden="true"
                  onClick={(e) => this.shiftRight()}
                >
                  {"<"}
                </button>
              </div>
            )}
            <div
              id="clm2"
              className="col-md-4 col-sm-3 box-pref"
              style={{ padding: "0px" }}
            >
              {this.state.columnarray2.map((obj, index) => (
                <div
                  style={{
                    borderBottom:
                      index !== this.state.columnarray2.length - 1 &&
                      "1px solid #e3e3e3",
                    // fontWeight: "500",
                  }}
                  key={index}
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                    key={index}
                    className="columnarray2"
                    onClick={() => {
                      this.selected(index, "clm2");
                    }}
                    value={obj.label}
                  >
                    {obj.label}
                  </p>
                </div>
              ))}
            </div>
            {/*   <div className="col-md-2 col-sm-3 up_down_btn_pre"> */}
            {!this.state.isMobile ? (
              <div className="col-md-1  up_down_btn_pre">
                <div
                  className="btn-group-vertical" /*style={{ maxWidth: '60px' }}*/
                >
                  <button
                    className="up-down-view-pref"
                    aria-hidden="true"
                    onClick={(e) => this.setColumnOrder(false)}
                  >
                    {">"}
                  </button>

                  <button
                    className="up-down-view-pref"
                    aria-hidden="true"
                    onClick={(e) => this.setColumnOrder(true)}
                  >
                    {"<"}
                  </button>
                </div>
                {/* <span className="" style={{ margin: 'auto 10px' }}>
                                <button
                                    type="button"
                                    // className="btnnn btn btn-primary submit_butn_of_setting"
                                    className="btnnn-sm btn btn-primary btn-sm"
                                    onClick={() => this.submitColumnbtn()}
                                >
                                    Submit
                                </button>
                            </span> */}
              </div>
            ) : (
              /*                    <div className="btn-group-vertical" >
                                      <button
                                          className="up-down-view-pref3"
                                          aria-hidden="true"
                                          onClick={(e) => this.setColumnOrder(false)}
                                      >
                                          {">"}
                                      </button>
      
                                      <button
                                          className="up-down-view-pref3"
                                          aria-hidden="true"
                                          onClick={(e) => this.setColumnOrder(true)}
                                      >
                                          {"<"}
                                      </button>
                                  </div> */
              <div className="d-flex justify-content-between">
                <div className="up_down d-flex justify-content-center pt-1 pb-1">
                  <button
                    /*   disabled={this.state.isColArrSelected} */
                    className="up-down-view-pref3"
                    aria-hidden="true"
                    onClick={(e) => this.setColumnOrder(false)}
                  >
                    {">"}
                  </button>
                  <button
                    /*    disabled={this.state.isColArr2Selected} */
                    className="up-down-view-pref3"
                    aria-hidden="true"
                    onClick={(e) => this.setColumnOrder(true)}
                  >
                    {"<"}
                  </button>
                </div>
                {/* <span className="" style={{ margin: 'auto 10px' }}>
                                <button
                                    type="button"
                                    // className="btnnn btn btn-primary submit_butn_of_setting"
                                    className="btnnn-sm btn btn-primary btn-sm"
                                    onClick={() => this.submitColumnbtn()}
                                >
                                    Submit
                                </button>
                            </span> */}
              </div>
            )}

            {/*     </div> */}
          </div>
          <div className="row">
            <div className="col-md"></div>
          </div>
        </div>

        <div className="modal-footer" style={{ marginTop: "10px" }}>
          <Button variant="secondary" onClick={this.props.handleClose2}>
            Close
          </Button>

          <Button variant="primary" onClick={() => this.submitColumnbtn()}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default PreferenceComponent;
