import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./global.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
/* import MainComponent from "./component/mainComponent"; */
/* import GlobalProvider from "./component/GlobalProvider "; */
import MainCompo from "./component/mainCompo";
import 'primereact/resources/primereact.min.css';  // PrimeReact core CSS
// import ModelList from "./component/model_list";
// import Work_In_Progress from "./component/work_in_progress";
// import ClientRegistration from "./component/client_registration";

ReactDOM.render(

  <div>
    {/* <GlobalProvider> */}
    {/* <MainComponent></MainComponent> */}

    {/* </GlobalProvider>, */}
      <MainCompo />
  </div>,
  document.getElementById("root")
);
