import React, { Component } from "react";
import "../css/inventory.css";
import inv from "../cssMod/inventory.module.css";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import WorkInProgressSmall from "./WorkInProgressSmall";
import { isMobile } from "react-device-detect";
import GlobalContext from "./GlobalContext ";

class InventoryComponent extends Component {
  static contextType = GlobalContext;
  state = {
    loca: this.props.loca,
    loading: false,
    page_error: false,
    inv_rec: [],
    searchRcd: [],
    stateLoading: false,
    workshops: [],
    isSearchInput: false,
    srch: "",
  };

  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
   this.initialFun();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isSearchInput && !prevState.isSearchInput) {
      if (this.searchInputRef.current) {
        this.searchInputRef.current.focus(); // Focus set kiya
      }
    }
  }

  initialFun = () => {
    var token = localStorage.getItem("token");
    // this.setState({ loading: true });
    axios
      .get(this.state.loca + "/loom/get/inventory", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const invrecord = resp.data;
          var workshops = [];
          if (invrecord.inventory) {
            var inven = invrecord.inventory[0];

            for (var t = 0; t < inven.workshops.length; t++) {
              workshops.push({ name: inven.workshops[t].name });
            }
          }
          if (invrecord !== "") {
            if ("Error" in invrecord) {
              this.setState({
                loading: false,
                page_error: true,
                error: invrecord.Error,
              });
            } else {
              this.setState({
                inv_rec: invrecord.inventory,
                searchRcd: invrecord.inventory,
                loading: false,
                workshops: workshops,
              });
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  callSearchbtn() {
    var val = this.state.srch;
    var new_rec = [];
    if (val.length > 2) {
      let rcd = JSON.stringify(this.state.inv_rec);
      let searchrcd = JSON.parse(rcd);
      for (let i = 0; i < searchrcd.length; i++) {
        let itm = searchrcd[i].name.toLowerCase();
        if (itm.includes(val.toLowerCase())) {
          new_rec.push({
            name: searchrcd[i].name,
            workshops: searchrcd[i].workshops,
            value: searchrcd[i].value,
          });
        }
      }
      this.setState({ searchRcd: new_rec });
    } else if (val === "") {
      this.initialFun();
      this.setState({ searchRcd: this.state.inv_rec });
    }
  }

  SearchInput = () => {
    if (this.state.isSearchInput === false) {
      this.setState({ isSearchInput: true });
    } else {
      this.initialFun();
      this.setState({ isSearchInput: false });
    }
  };

  changeSearch(val) {
    if (val === "") {
      this.setState({ srch: "" });
      this.initialFun();
    } else {
      this.setState({ srch: val });
    }
  }

  render() {
    return (
      <div className="pagesetup">
        {this.state.loading === true ? (
          <WorkInProgress></WorkInProgress>
        ) : (
          <div className={inv.inventory_outerline}>
            <div>
              {this.state.stateLoading === true ? (
                <WorkInProgressSmall></WorkInProgressSmall>
              ) : null}
            </div>
            <div className="mb-2 text-center fw-bolder fs-5">
              Inventory Records
            </div>
            <div className="table-responsive table-responsive-lg overflow-auto"
                            style={{
                              height: isMobile
                                ? this.context.formHeight - 91
                                : 
                                this.context.formHeight - 10.6,

                              position: "relative",
                            }}
            >
              <table
                className={`table table-striped table-hover ${inv.tab_layout} table-bordered align-middle`}
              >
                <thead className="table-dark"             
                  style={{
                      position: "sticky",
                      top: "0",
                    }}>
                  <tr className="text-center ">
                    <th
                      className={`text-center ${inv.itm}`}
                      scope="col"
                      width="25%"
                    >
                      <span
                        className=""
                        style={{
                          marginLeft: !this.state.isSearchInput ? "25px" : "",
                        }}
                      >
                        Item
                      </span>
                      <span>
                        {/* {this.state.isSearchInput && ( */}
                        <input
                          className={`${inv.srch_inv} form-control py-0 ${
                            this.state.isSearchInput ? inv.open : ""
                          }`}
                          type="search"
                          aria-label="Search"
                          value={this.state.srch}
                          aria-hidden="true"
                          onChange={(e) => this.changeSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.callSearchbtn();
                            }
                          }}
                          ref={this.searchInputRef}
                        />
                        {/* )} */}
                        <i
                          className={
                            this.state.isSearchInput === false
                              ? "fa-solid fa-magnifying-glass ps-2"
                              : "fa-solid fa-xmark ps-2"
                          }
                          onClick={() => this.SearchInput()}
                        ></i>
                        {/* <i class="fa-solid fa-xmark"></i> */}
                      </span>
                    </th>
                    <th className={`text-center ${inv.qnt}`} scope="col">
                      Quantity
                    </th>
                    {this.state.workshops.map((obj, ind) => (
                      <th
                        className={`text-center ${inv.workshp}`}
                        scope="col"
                        width="25%"
                        key={ind}
                      >
                        {obj.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {!this.state.searchRcd && (
                    <tr>
                      <td></td>
                      <td className="h6 " style={{ paddingRight: "25%" }}>
                        No record found
                      </td>
                    </tr>
                  )}
                  {this.state.searchRcd &&
                    this.state.searchRcd.length > 0 &&
                    this.state.searchRcd.map((obj, index) => (
                      <tr key={index}>
                        <td>{obj.name}</td>
                        <td>{obj.value}</td>
                        {obj.workshops.map((obji, indexi) => (
                          <td className={inv.workshp} key={indexi}>
                            {obji.value}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InventoryComponent;
