import React, { Component } from "react";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import { Input } from "reactstrap";
import { Button, Modal as Mod } from "react-bootstrap";
import { json } from "react-router-dom";
import WithNavigate from "./withNavigate";
import "../css/loomstatus.css";

class LoomStatusComponent extends Component {
  state = {
    records: [],
    loading: false,
    loca: this.props.loca,
    page_error: false,
    error: "",
    page_message: false,
    message: "",
    status: "",
    loom_num: "",
    workshopRec: [],
    workshop_name: "",
    currentWokId: "",
    showButton: false,
    off_all: false,
    model_error: false,
    reason: "",
    closebtn: false,
    issueType: [],
    type: "",
    on_off: [],
    setStatus: "",
    showModal: false, // Control the modal visibility
    modalMessage: "", // The message to display in the modal
    currentSelectedStatusId: "0",
  };

  constructor(props) {
    super(props);
    this.callbtn = this.callbtn.bind(this);
    this.getStatusRecords = this.getStatusRecords.bind(this);
    this.setValue = this.setValue.bind(this);
    this.closeList = this.closeList.bind(this);
    this.btnSubmitFun = this.btnSubmitFun.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.callFunc = this.callFunc.bind(this);

    this.state.issueType.push({ name: "none", label: "None" });
    this.state.issueType.push({ name: "robberyIssue", label: "Robbery Issue" });
    this.state.issueType.push({
      name: "inventoryIssue",
      label: "Inventory Issue",
    });
    this.state.issueType.push({
      name: "electricityIssue",
      label: "Electricity Issue",
    });
    this.state.issueType.push({
      name: "loomComponentIssue",
      label: "LoomComponent Issue",
    });
    this.state.issueType.push({ name: "strikeIssue", label: "Strike Issue" });
    this.state.issueType.push({ name: "taxIssue", label: "Tax Issue" });
    this.state.issueType.push({ name: "generalIssue", label: "General Issue" });
    this.state.issueType.push({
      name: "employee Issue",
      label: "Employee Issue",
    });
    this.state.issueType.push({ name: "orderIssue", label: "Order Issue" });
    this.state.issueType.push({ name: "vendorIssue", label: "Vendor Issue" });
    this.state.issueType.push({ name: "paymentIssue", label: "Payment Issue" });
    this.state.issueType.push({
      name: "infrastructureIssue",
      label: "Infrastructure Issue",
    });
    this.state.issueType.push({ name: "other", label: "Other" });

    this.state.on_off.push({ name: "none", label: "None" });
    this.state.on_off.push({ name: "onAll", label: "On All" });
    this.state.on_off.push({ name: "offAll", label: "Off All" });
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/get/workshop/record", {
        headers: { authorization: "Bearer " + token },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if (res !== "") {
            if ("Error" in res) {
              this.setState({
                loading: false,
                page_error: true,
                error: res.Error,
              });
            } else {
              this.setState({ workshopRec: res.workshop, loading: false });
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  }

  getStatusRecords(val) {
    if (val !== "") {
      if (val === "none") {
        this.setState({ records: [], loading: false, workshop_name: val });
      } else {
        // let obj=this.state.workshopRec.filter((obj, ind) => obj.workshopId===val);

        this.setState({ records: [], currentWokId: val });
        var token = localStorage.getItem("token");
        axios
          .get(this.state.loca + "/loom/get/status/record/" + val, {
            headers: { authorization: "Bearer " + token },
          })
          .then(
            (resp) => {
              let res = resp.data;
              if (res !== "") {
                if ("Error" in res) {
                  this.setState({
                    loading: false,
                    page_error: true,
                    error: res.Error,
                  });
                } else {
                  this.setState({
                    records: res.loom,
                    loading: false,
                    closebtn: false,
                  });
                }
              }
            },
            (error) => {
              this.props.showErrorCompo();
            }
          );

        this.setState({ setStatus: "none" });
      }
    }
  }

  // Method to show the modal with a specific message
  handleActionClick = (message) => {
    this.setState({
      showModal: true,
      modalMessage: message,
    });
  };

  // Method to hide the modal
  handleCloseModal = (close) => {
    if (!close) {
      const { navigateWithSearchParams } = this.props;

      var token = localStorage.getItem("token");
      axios
        .get(
          this.state.loca +
            "/loom/get/singlerecord/" +
            "loom_status" +
            "/" +
            this.state.currentSelectedStatusId,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const relrecord = resp.data;
            //    this.calledfromDidMount(relrecord);
            if ("Error" in relrecord) {
              // this.setState({
              //   loading: false,
              //   page_error: true,
              //   error: relrecord.Error,
              // });
            } else {
              var rcd = relrecord.formRecord[2].record;

              let id = "0";
              let disPlayCol = "";
              let uni_id = "";
              for (let i = 0; i < rcd.length; i++) {
                if (rcd[i].name === "id") {
                  id = rcd[i].value;
                } else if (rcd[i].displayColumn === "true") {
                  disPlayCol = rcd[i].value;
                } else if (rcd[i].name === "uni_id") {
                  uni_id = rcd[i].value;
                }
              }
              if (disPlayCol === "") disPlayCol = uni_id;
              let obj = { id: id, value: disPlayCol };

              this.setState({ showModal: false });
              navigateWithSearchParams(
                "/private/form",
                {
                  rty: "new",
                  tableName: "loom_issue",
                  rid: 0,
                },
                { tabName: "loom_status", value: obj, rcd: rcd }
              );
            }
          },
          (error) => {
            // let err = { message: error.message, code: error.response.status };
            // this.props.showErrorCompo({ state: { err: err } });
          }
        );
    } else {
      this.setState({
        showModal: false,
        modalMessage: "",
      });
    }
  };

  callbtn(val, workshop, loom, workshopName, loomName, statusId) {
    if (val !== "" || val !== null) {
      if (val === "on") {
        var status = {
          workshop_id: workshop,
          workshop_name: workshopName,
          loom_id: loom,
          loom_name: loomName,
          tableName: "loom_issue",
        };
        // localStorage.setItem("status", JSON.stringify(status));
        //  alert("create Issue Record");
        //  this.props.showFormCompo("loom_issue", 0, "new");
        this.handleActionClick("Create Loom Issue");

        this.setState({
          showButton: true,
          loom_num: loom,
          currentSelectedStatusId: statusId,
        });
      } else {
        let body = {
          Status_val: val,
          /*      workshop_number: workshop, */
          loom_number: loom,
          workshopId: this.state.currentWokId,
        };
        var token = localStorage.getItem("token");

        axios
          .post(this.state.loca + "/loom/update/status/record", body, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then(
            (resp) => {
              let res = resp.data;
              if (res !== "") {
                if ("Error" in res) {
                  this.setState({
                    loading: false,
                    page_error: true,
                    error: res.Error,
                    showButton: false,
                  });
                } else {
                  this.setState({
                    loading: false,
                    status: res.status,
                    loom_num: res.loom_number,
                    showButton: false,
                  });
                  if (res.status === "on") {
                    this.getStatusRecords(this.state.currentWokId);
                  }
                }
              }
            },
            (error) => {
              this.props.showErrorCompo();
            }
          );
      }
    }
  }

  createRecord() {
    const { navigateWithSearchParams } = this.props;
    //this.props.showFormCompo("loom_issue", 0, "new");
    navigateWithSearchParams(
      "/private/form",
      {
        rty: "new",
        tableName: `loom_issue`,
        rid: `0`,
      },
      {}
    );
  }

  setValue(val) {
    this.setState({ off_all: val });
  }

  closeList() {
    this.setState({ off_all: false });
  }

  setReason(e) {
    this.setState({ reason: e.target.value, model_error: false });
  }

  setIssueType(e) {
    this.setState({ type: e.target.value });
  }

  changeStatus(val) {
    if (val === "onAll") {
      this.setState({ setStatus: val });
      let res = this.state.records.find(
        (loomStatus) => loomStatus.status === "off"
      );
      if (res) this.btnSubmitFun("", val);
    } else if (val === "offAll") {
      let res = this.state.records.find(
        (loomStatus) => loomStatus.status === "on"
      );
      if (!res) {
        this.setState({
          model_error: true,
          error:
            "Already all looms are off , if you will submit again new loom issue will be made for every loom",
        });
      }
      this.setState({ setStatus: val, off_all: true });
    } else {
      this.setState({ setStatus: val });
    }
  }

  btnSubmitFun(val, status) {
    if (status === "offAll") {
      if (
        val === null ||
        (val === "" && this.state.type === null) ||
        this.state.type === ""
      ) {
        this.setState({
          model_error: true,
          error: "Please fill fields mark in red",
        });
      } else if (
        this.state.type === null ||
        this.state.type === "" ||
        this.state.type === "none"
      ) {
        this.setState({
          model_error: true,
          error: "Please fill fields mark in red",
        });
      } else if (val === null || val === "") {
        this.setState({
          model_error: true,
          error: "Please fill fields mark in red",
        });
      } else {
        let json = {
          /*  workshop_number: this.state.workshop_num, */
          reason: this.state.reason,
          issue_type: this.state.type,
          on_off: status,
          workShopId: this.state.currentWokId,
        };

        this.callFunc(json);
      }
    } else if (status === "onAll") {
      let json = {
        /*   workshop_number: this.state.workshop_num, */
        reason: this.state.reason,
        issue_type: this.state.type,
        on_off: status,
        workShopId: this.state.currentWokId,
      };

      this.callFunc(json);
    }
  }

  callFunc(json) {
    var token = localStorage.getItem("token");
    axios
      .post(this.state.loca + "/loom/status/set/onoffall", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let res = resp.data;
        this.setState({ off_all: false, records: res.loom, closebtn: true });
      });
  }

  render() {
    return (
      <div className="pagesetup">
        {this.state.loading === true ? (
          <WorkInProgress></WorkInProgress>
        ) : (
          <div>
            {/* <div>
              <label className="d-flex" style={{ fontWeight: "bold" , }}>
                Select Workshop
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  this.getStatusRecords(e.target.value);
                }}
                value={this.state.workshop_num}
              >
                <option value={"none"}>None</option>
                {this.state.workshopRec.map((obj, ind) => (
                  <option key={ind} value={obj.workshopId}>
                    {obj.workshopNumber}
                  </option>
                ))}
              </select>
            </div>
            {this.state.page_error === true && (
              <div className="alert alert-danger form_alt" role="alert">
                {this.state.error}
              </div>
            )}
            {this.state.page_message === true && (
              <div className="alert alert-success form_alt" role="alert">
                {this.state.message}
              </div>
            )}
            {this.state.records.length === 0 && <div>No Record Found</div>} */}
            {/* {this.state.records.length !== 0 && (
              <div>
                <div
                  className="row"
                  style={{ marginTop: "7px", marginLeft: "9px" }}
                >
                  <div className="col-md-2">
                    <span>Update All Records:</span>
                  </div>
                  <div className="col-md-1">
                    <select
                      className=" selct_optfil "
                      onChange={(e) => this.changeStatus(e.target.value)}
                      value={this.state.setStatus}
                      // style={{ marginLeft: "-80px" }}
                    >
                      {this.state.on_off.map((clm, cl_o) => (
                        <option value={clm.name} key={cl_o}>
                          {clm.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {this.state.records.length !== 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                      <td className="h6">Status Records</td>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Loom Number</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                  {this.state.records.map((obj_in, index_in) => (
                    <tr key={index_in}>
                      <td>{obj_in.loom}</td>
                      <td>{obj_in.status}</td>
                      <td>
                        {obj_in.status === "on" ||
                        (this.state.status === "on" &&
                          obj_in.loom === this.state.loom_num) ? (
                          <i
                            className="fa fa-toggle-off"
                            style={{
                              color: "#269900",
                              fontSize: "larger",
                              fontWeight: "bold",
                            }}
                            aria-hidden="true"
                            onClick={() =>
                              this.callbtn(
                                obj_in.status,
                                obj_in.workshop,
                                obj_in.loomId,
                                obj_in.workshopName,
                                obj_in.loomName
                              )
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa fa-toggle-on"
                            style={{
                              color: "#cc0000",
                              fontSize: "larger",
                              fontWeight: "bold",
                            }}
                            aria-hidden="true"
                            onClick={() =>
                              this.callbtn(
                                obj_in.status,
                                obj_in.workshop,
                                obj_in.loomId,
                                obj_in.workshopName,
                                obj_in.loomName
                              )
                            }
                          ></i>
                        )}
                      </td>
               
                    </tr>
                  ))}
                </tbody>
              </table>
            )} */}

            <div className="container-fluid">
              <div className="mb-3">
                <label className="d-flex font-weight-bold mb-2">
                  Select Workshop
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    const selectedIndex = e.target.selectedIndex - 1; // Adjust for the "None" option at index 0
                    if (selectedIndex >= 0) {
                      const selectedWorkshop =
                        this.state.workshopRec[selectedIndex];
                      this.setState({
                        workshop_name: selectedWorkshop.workShopName, // Update to show the name in the dropdown
                      });

                      this.getStatusRecords(selectedWorkshop.workshopId);
                    }
                  }}
                  value={this.state.workshop_name || "none"}
                >
                  <option value="none">None</option>
                  {this.state.workshopRec.map((obj, ind) => (
                    <option key={ind} value={obj.workShopName}>
                      {obj.workShopName}
                    </option>
                  ))}
                </select>
              </div>

              {/* Alert Messages */}
              {this.state.page_error && (
                <div className="alert alert-danger mb-3" role="alert">
                  {this.state.error}
                </div>
              )}
              {this.state.page_message && (
                <div className="alert alert-success mb-3" role="alert">
                  {this.state.message}
                </div>
              )}

              {/* No Records Found Message */}
              {this.state.records.length === 0 && (
                <div className="mb-3">No Record Found</div>
              )}
              {this.state.records.length !== 0 && (
                <div className="update-records-section">
                  <div className="row align-items-center mb-3">
                    <div className="col-md-3 col-sm-6 col-12">
                      <span className="update-label">Update All Records:</span>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12">
                      <select
                        className="form-select form-selecttt"
                        onChange={(e) => this.changeStatus(e.target.value)}
                        value={this.state.setStatus}
                      >
                        {this.state.on_off.map((clm, cl_o) => (
                          <option value={clm.name} key={cl_o}>
                            {clm.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {this.state.records.length !== 0 && (
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead className="table-header">
                      <tr>
                        <th colSpan="3" className="text-center h6">
                          Loom Status Records
                        </th>
                      </tr>
                      <tr>
                        <th className="text-center">Loom</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.records.map((obj_in, index_in) => (
                        <tr key={index_in}>
                          <td className="text-center">{obj_in.loomName}</td>
                          <td className="text-center">
                            <span
                              className={`badge ${
                                obj_in.status === "on"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {obj_in.status}
                            </span>
                          </td>
                          <td className="text-center">
                            {obj_in.status === "on" ? (
                              <i
                                className="fa fa-toggle-off toggle-icon"
                                style={{ color: "green" }}
                                aria-hidden="true"
                                onClick={() =>
                                  this.callbtn(
                                    obj_in.status,
                                    obj_in.workshopId,
                                    obj_in.loomId,
                                    obj_in.workshopName,
                                    obj_in.loomName,
                                    obj_in.statusId
                                  )
                                }
                              ></i>
                            ) : (
                              <i
                                className="fa fa-toggle-on toggle-icon"
                                style={{ color: "red" }}
                                aria-hidden="true"
                                onClick={() =>
                                  this.callbtn(
                                    obj_in.status,
                                    obj_in.workshop,
                                    obj_in.loomId,
                                    obj_in.workshopName,
                                    obj_in.loomName,
                                    obj_in.statusId
                                  )
                                }
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {this.state.showModal && (
                <div
                  className="modal show fade d-block"
                  tabIndex="-1"
                  role="dialog"
                  style={{
                    top: "10%",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Alert</h5>
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={this.handleCloseModal}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>{this.state.modalMessage}</p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => {
                            this.handleCloseModal("close");
                          }}
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.handleCloseModal();
                          }}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <Mod show={this.state.off_all} onHide={this.closeList} size="xl">
          <Mod.Header closeButton>
            <Mod.Title>Reason</Mod.Title>
          </Mod.Header>
          {this.state.model_error === true && (
            <div className="alert alert-danger form_alt" role="alert">
              {this.state.error}
            </div>
          )}
          <Mod.Body>
            <div className="row">
              <div className="col-md-2">Issue Type :</div>
              <div className="col-md-1">
                <select
                  className={
                    this.state.model_error === true
                      ? "selct_optfil unverifi"
                      : "selct_optfil "
                  }
                  onChange={(e) => this.setIssueType(e)}
                  value={this.state.type}
                  style={{ marginBottom: "15px" }}
                >
                  {this.state.issueType.map((clm, cl_o) => (
                    <option value={clm.name} key={cl_o}>
                      {clm.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <Input
              className={
                this.state.model_error === true
                  ? "lom_form_control formpadd unverifi"
                  : "lom_form_control "
              }
              type="text"
              placeholder="Status Off Reason"
              onChange={(e) => {
                this.setReason(e);
              }}
            ></Input>
          </Mod.Body>
          <Mod.Footer>
            <Button
              onClick={(e) => {
                this.btnSubmitFun(this.state.reason, "offAll");
              }}
            >
              Submit
            </Button>
          </Mod.Footer>
        </Mod>
      </div>
    );
  }
}
export default WithNavigate(LoomStatusComponent);
