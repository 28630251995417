import React, { Component } from "react";
import "../css/uipage.css";
import WithNavigate from "./withNavigate";
import axios from "axios";

class UiPageComponent extends Component {
  state = {
    name: this.props.name,
    loca: this.props.loca,
    filter: {
      filter: [
        {
          co: "name",
          cl: "Name",
          mc: "=",
          an: "Client Details",
          ct: "String",
          af: "",
          rf: { id: "", value: "" },
        },
      ],
    },
  };

  constructor(props) {
    super(props);
    this.callbtn = this.callbtn.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  callbtn(event) {
    // this.props.showClientInfo(this.state.filter);
    // this.props.showVariableCompo(this.state.filter);
    if (event.target.value === "Fill Details") {
      const { navigateWithSearchParams } = this.props;
      let fill = this.state.filter;
      navigateWithSearchParams("/private/variable", {}, { filter: fill });
    } else if (event.target.value === "Skip Details") {
      var token = localStorage.getItem("token");
      axios
        .get(this.state.loca + "/loom/change/landingPref", {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          const rec = res.data;
          this.props.navigate("/private");
          // this.props.navigate("/private/dashboard")
          window.location.reload(true);
        });
    }
  }

  render() {
    return (
      <div className="ui">
        {true && (
          <div className="uipage uiform">
            <div className="hedd">Welcome To LoomYarn</div>
            <br></br>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-3"></div>
              <div className="col-md-4">
                <div className="hedd2">
                  Fill up your details. It will help in setting up your system.
                </div>
                {/* <button className="mainbtn" onClick={this.callbtn}>
                  Click Here
                </button> */}
                <select className="mainbtn" onChange={this.callbtn}>
                  <option>Click Here</option>
                  <option>Fill Details</option>
                  <option>Skip Details</option>
                </select>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WithNavigate(UiPageComponent);
