export function getShowMatchFilter(column) {
    let isAnyExistingFilter = false;
    console.log("getShowMatchFilter", JSON.parse(JSON.stringify(column)));

    let currentFilter = column?.currentFilter
    if (currentFilter?.length != 0 && currentFilter.length == 1) {
        let ftt = currentFilter[0]
        if (ftt.co || ftt.mc) {
            isAnyExistingFilter = true;
        }
    } else if (currentFilter.length > 1) {
        isAnyExistingFilter = true;
    }

    let filt = {}
    if (isAnyExistingFilter) {
        filt = {
            co: "",
            cl: "",
            mc: "",
            an: "",
            ct: "",
            af: "",
            rf: { id: "", value: "" },
            dc: { id: "", value: "", label: "" },
            ch: [],
        }
    } else {
        filt = currentFilter[0]
    }
    // is->  str ,email ,choice 
    //  =   -> int ,bool ,date ,dtm ,ref


    filt.cl = column.cl
    filt.co = column.co
    filt.ct = column.type
  //  filt.an = column.an
    setAnswer(filt, column)
    setMiddleCoulm(filt, column)
    if (isAnyExistingFilter) {
        filt.af = "AND";
        currentFilter.push?.(filt);
    }
    if(column.type==="choice"){
        filt.ch=column.ch
    }


}

function setMiddleCoulm(filt, column)
{
    if (column?.type == "String" || column?.type == "email" || column?.type == "choice") {
        filt.mc = "is"

    } else if (column?.type == "boolean" || column?.type == "int" || column?.type == "datetime"
         || column?.type == "date" || column?.type == "reference" || column?.type == "multi_select") {
        filt.mc = "="
    }
}

function setAnswer(filt ,column)
{
    if(column.type=="reference"){
        filt.rf = { id: column.an?.id, value: column.an?.value };
    }else if(column.type=="datetime"){
        filt.an=column.an.replace(" ","T")
    }else{
        filt.an=column.an
    }

}


